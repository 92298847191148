import { Action, Label, Tracking, useTracking } from "~/lib/client/tracking";

export interface AnalyticsClickHandlerProps {
  // for testing
  tracking?: Tracking;
  trackAction?: Action;
  trackVariant?: string;
  trackLabel?: Label;
  onClick?: () => void;
}

export const useAnalyticsClickHandler = ({
  trackAction: action,
  trackVariant: variant = "",
  trackLabel: label,
  tracking,
  onClick,
}: AnalyticsClickHandlerProps) => {
  const track = useTracking();
  return () => {
    if (action) {
      (tracking || track).clicked(action, label, variant);
    }
    if (onClick) onClick();
  };
};
