import { LiveStatus, LoadingLiveStatus } from "../live-status";
import { useProvideLiveStatus } from "./useProvideLiveStatus";
import React, { createContext, useContext } from "react";

const defaultContext: LoadingLiveStatus = {
  status: "loading",
};

const LiveStatusContext = createContext<LiveStatus>(defaultContext);

export const ProvideLiveStatus: React.FC = ({ children }) => {
  const liveStatus = useProvideLiveStatus("fast");

  return (
    <LiveStatusContext.Provider value={liveStatus}>
      {children}
    </LiveStatusContext.Provider>
  );
};

export const useLiveStatus = () => useContext(LiveStatusContext);
