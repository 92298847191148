import {
  Box,
  Grid,
  GridItem,
  LogoLockupStacked,
  Stack,
  Words,
} from "design-system";
import { Link } from ".";
import { NewsletterSignup } from "../common/NewsletterSignup";
import ExternalLink from "./ExternalLink";
import InstagramIcon from "~/images/social-icons/instagram.svg";
import React, { FC } from "react";
import SpotifyIcon from "~/images/social-icons/spotify.svg";
import styled from "styled-components";
import TwitterIcon from "~/images/social-icons/twitter.svg";

const Container = styled.div(({ theme }) => ({
  fontFamily: theme.fontFamilies.extended,
  fontWeight: theme.fontWeights.medium,
  padding: `0 ${theme.gutters.mobile}`,
  [theme.mediaQueries.tablet]: {
    padding: `0 ${theme.gutters.tablet}`,
  },
  [theme.mediaQueries.desktop]: {
    padding: `0 ${theme.gutters.desktop}`,
  },
}));

const FooterLink = styled(Link)({
  "&:hover": {
    textDecoration: "none",
  },
});

const FooterWords = styled(Words)(({ theme }) => ({
  "&:hover": {
    textDecoration: "none",
    color: theme.colors.hype,
  },
}));

const Copyright = styled.p(({ theme }) => ({
  color: "#A3A3A3",
  fontSize: "12px",
  fontWeight: theme.fontWeights.black,
  lineHeight: "16px",
  whiteSpace: "nowrap",
}));

const DCLogo = styled(LogoLockupStacked)(({ theme }) => ({
  display: "none",
  marginTop: "5px",

  [theme.mediaQueries.desktop]: {
    display: "block",
    height: "40px",
    marginBottom: "58px",
    width: "98px",
  },
}));

const List = styled.ul({
  listStyle: "none",
  margin: 0,
  padding: 0,
});

const ListItem = styled.li({
  marginBottom: "8px",
  "&:last-of-type": {
    marginBottom: 0,
  },
});

const ColumnHeading = styled(Words)({
  marginBottom: "15px",
  textTransform: "uppercase",
  whiteSpace: "nowrap",
});

const FollowDC = styled(ColumnHeading)(({ theme }) => ({
  marginBottom: 0,
  marginRight: 12,
  display: "none",

  [theme.mediaQueries.desktop]: {
    display: "flex",
  },
}));

const SocialLink = styled(ExternalLink)(() => ({
  // marginRight: "12px",
  marginLeft: "14px",
  "&:first-of-type": {
    marginLeft: 0,
  },
}));

const ColumnSection = styled.div(({ theme }) => ({
  borderBottom: "1px solid #565656;",
  width: "100%",
  [theme.mediaQueries.desktop]: {
    borderBottom: "none",
    borderTop: "none",
  },
}));

const GridDisplay = styled(GridItem)(({ theme }) => ({
  display: "block",
  [theme.mediaQueries.desktop]: {
    display: "none",
  },
}));

const GridDisplayMiddle = styled(GridItem)(({ theme }) => ({
  display: "block",
  [theme.mediaQueries.tablet]: {
    display: "none",
  },
}));

const LogoCopyright = styled(GridItem)(({ theme }) => ({
  display: "none",
  [theme.mediaQueries.desktop]: {
    display: "flex",
  },
}));

const DesktopSocials = styled(GridItem)(({ theme }) => ({
  display: "none",
  [theme.mediaQueries.desktop]: {
    display: "flex",
  },
}));

const BottomRow = styled(Grid)(({ theme }) => ({
  alignItems: "flex-end",
  display: "none",
  marginTop: "69px",
  [theme.mediaQueries.desktop]: {
    display: "flex",
  },
}));

export const Footer: FC<{ className?: string }> = ({ className }) => {
  return (
    <Container id="footer" className={className}>
      <Grid>
        <LogoCopyright columnStart={1} columnSpan={2}>
          <Stack gap="xxxxxl" orientation="vertical">
            <DCLogo />
          </Stack>
        </LogoCopyright>
        <GridItem columnStart={[1, 1, 3]} columnSpan={2}>
          <ColumnHeading variant="footerSmall">Dance Church</ColumnHeading>
          <List>
            <ListItem>
              <FooterLink variant="nav" href="/about">
                <FooterWords variant="footerMedium" color="white">
                  About
                </FooterWords>
              </FooterLink>
            </ListItem>
            <ListItem>
              <FooterLink variant="nav" href="/teachers">
                <FooterWords variant="footerMedium" color="white">
                  Teachers
                </FooterWords>
              </FooterLink>
            </ListItem>
            <ListItem>
              <FooterLink variant="nav" href="/blog">
                <FooterWords variant="footerMedium" color="white">
                  Blog
                </FooterWords>
              </FooterLink>
            </ListItem>
            <ListItem>
              <FooterLink href="https://shop.dancechurch.com/">
                <FooterWords variant="footerMedium" color="white">
                  Merch
                </FooterWords>
              </FooterLink>
            </ListItem>
          </List>
        </GridItem>
        <GridItem columnStart={[3, 3, 5]} columnSpan={2}>
          <ColumnHeading variant="footerSmall">On-Demand</ColumnHeading>
          <List>
            <ListItem>
              <FooterLink variant="nav" href="/on-demand">
                <FooterWords variant="footerMedium">All Classes</FooterWords>
              </FooterLink>
            </ListItem>
            <ListItem>
              <FooterLink variant="nav" href="/on-demand/category/dance-church">
                <FooterWords variant="footerMedium">Dance Church</FooterWords>
              </FooterLink>
            </ListItem>
            <ListItem>
              <FooterLink variant="nav" href="/on-demand/category/body-time">
                <FooterWords variant="footerMedium">Body Time</FooterWords>
              </FooterLink>
            </ListItem>
            <ListItem>
              <FooterLink variant="nav" href="/on-demand/category/more">
                <FooterWords variant="footerMedium">More</FooterWords>
              </FooterLink>
            </ListItem>
          </List>
        </GridItem>
        <GridDisplayMiddle columnStart={1} columnSpan={8}>
          <ColumnSection />
        </GridDisplayMiddle>
        <GridItem columnStart={[1, 5, 7]} columnSpan={2}>
          <List>
            <ListItem>
              <ColumnHeading variant="footerSmall">In-Person</ColumnHeading>
            </ListItem>
            <ListItem>
              <FooterLink variant="nav" href="/irl">
                <FooterWords variant="footerMedium">All Locations</FooterWords>
              </FooterLink>
            </ListItem>
            <ListItem>
              <FooterLink variant="nav" href="/irl?city=Los Angeles">
                <FooterWords variant="footerMedium">LA</FooterWords>
              </FooterLink>
            </ListItem>
            <ListItem>
              <FooterLink variant="nav" href="/irl?city=Brooklyn">
                <FooterWords variant="footerMedium">NYC</FooterWords>
              </FooterLink>
            </ListItem>
            <ListItem>
              <FooterLink variant="nav" href="/irl?city=Seattle">
                <FooterWords variant="footerMedium">Seattle</FooterWords>
              </FooterLink>
            </ListItem>
          </List>
        </GridItem>
        <GridItem columnStart={[3, 7, 9]} columnSpan={2}>
          <List>
            <ListItem>
              <ColumnHeading variant="footerSmall">Info</ColumnHeading>
            </ListItem>
            <ListItem>
              <FooterLink variant="nav" href="mailto:go@dancechurch.com">
                <FooterWords variant="footerMedium">Contact</FooterWords>
              </FooterLink>
            </ListItem>
            <ListItem>
              <FooterLink variant="nav" href="/faq">
                <FooterWords variant="footerMedium">FAQ</FooterWords>
              </FooterLink>
            </ListItem>
            <ListItem>
              <FooterLink variant="nav" href="/legal/terms">
                <FooterWords variant="footerMedium">Legal</FooterWords>
              </FooterLink>
            </ListItem>
            <ListItem>
              <FooterLink variant="nav" href="/legal/privacy">
                <FooterWords variant="footerMedium">Privacy</FooterWords>
              </FooterLink>
            </ListItem>
            {/*<ListItem>
              <FooterLink variant="nav" href="/careers">
                <FooterWords variant="footerMedium">Careers</FooterWords>
              </FooterLink>
            </ListItem>*/}
          </List>
        </GridItem>

        <DesktopSocials columnStart={[0, 0, 11]} columnSpan={2}>
          <Box
            display={"flex"}
            alignItems={"flex-start"}
            justifyContent="flex-end"
          >
            <FollowDC variant="footerSmall">Follow DC</FollowDC>
            <Box display="flex">
              <SocialLink href={"https://www.instagram.com/dance_church/"}>
                <InstagramIcon />
              </SocialLink>
              <SocialLink href={"https://twitter.com/Dance_Church"}>
                <TwitterIcon />
              </SocialLink>
              <SocialLink
                href={
                  "https://open.spotify.com/user/ykkxmefcftd9gcpm3diodypcq?si=rxSyXXIsTEuI-QlT_xA6kg&nd=1"
                }
              >
                <SpotifyIcon />
              </SocialLink>
            </Box>
          </Box>
        </DesktopSocials>

        <GridDisplay columnStart={1} columnSpan={8}>
          <ColumnSection />
        </GridDisplay>
        <GridDisplay columnStart={[1, 1, 7]} columnSpan={[8]}>
          <Box mt={[0, 0, "20px"]} display={["block", "block", "none"]}>
            <NewsletterSignup />
          </Box>
        </GridDisplay>
        <GridDisplay columnStart={1} columnSpan={8}>
          <ColumnSection />
        </GridDisplay>
        <GridDisplay columnSpan={8}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="baseline"
          >
            {/* <Socials> */}
            <Copyright> ©2022 Dance Church® </Copyright>
            <div>
              <SocialLink href={"https://www.instagram.com/dance_church/"}>
                <InstagramIcon />
              </SocialLink>
              <SocialLink href={"https://twitter.qcom/Dance_Church"}>
                <TwitterIcon />
              </SocialLink>
              <SocialLink
                href={
                  "https://open.spotify.com/user/ykkxmefcftd9gcpm3diodypcq?si=rxSyXXIsTEuI-QlT_xA6kg&nd=1"
                }
              >
                <SpotifyIcon />
              </SocialLink>
            </div>
            {/* </Socials> */}
          </Box>
        </GridDisplay>
      </Grid>

      <BottomRow>
        <GridItem columnStart={[0, 0, 0]} columnSpan={4}>
          <Stack gap="m" orientation="horizontal">
            <Copyright> ©2022 Dance Church® </Copyright>
            <Copyright> Every Body, All Heart. </Copyright>
          </Stack>
        </GridItem>
        <GridItem columnStart={[0, 0, 7]} columnSpan={2}>
          <Box display={["none", "block", "block"]}>
            <NewsletterSignup />
          </Box>
        </GridItem>
      </BottomRow>
    </Container>
  );
};
