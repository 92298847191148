import { Action, Label } from "~/lib/client/tracking";
import { AnimatePresence, motion } from "~/lib/client/framer";
import { BetaLink, BetaQuestionMark, BetaText, LogoLink } from "./styled";
import { getIsFirstLoad } from "../Loading";
import { LogoLockupStacked } from "design-system";
import { NavFooter } from "../NavFooter";
import { theme } from "~/theme";
import { TopNav } from "../TopNav";
import { useAccount } from "~/lib/client/account";
import { useAsync } from "~/lib/client/loading";
import { usePreventGlobalScrolling } from "../ScrollFix";
import { zIndices } from "~/lib/client/zIndices";
import BetaFlag from "~/images/beta.svg";
import Link from "next/link";
import MainNav from "../MainNav";
import QuestionMark from "~/images/question-mark.svg";
import React, { FC, ReactNode, useEffect, useState } from "react";
import router, { useRouter } from "next/router";
import styled from "styled-components";
import styles from "./SiteHeader.module.scss";

export const navHeight = 94;

const LogInOutText = styled.p<{ isNavOpen?: boolean }>`
  cursor: pointer;
  display: "none";
  font-family: ${theme.fontFamilies.standard};
  font-size: 14px;
  font-weight: ${theme.fontWeights.black};
  margin-right: 10px;
  z-index: 5;
  color: ${(props) => (props.isNavOpen ? "#000" : "#fff")};
  line-height: 1;

  @media screen and (min-width: 801px) and (max-width: 1141px) {
    font-size: 12px;
  }

  &:hover {
    color: ${theme.colors.hype};
  }

  &:active {
    color: ${theme.colors.hype};
  }
`;

const LogContainer = styled.div`
  z-index: 5;
`;

const TopRightButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const SignUpContainer = styled.div`
  display: flex;
  z-index: 5;
`;

const AccountButton = styled.a<{ isNavOpen?: boolean }>`
  background-color: ${(props) => (props.isNavOpen ? "#000" : "#C458E1")};
  color: ${(props) => (props.isNavOpen ? "#6C3CCE" : "#000")};
  z-index: 22;
  &:hover {
    color: ${(props) => (props.isNavOpen ? "#6c3cce" : "#000")};
    background-color: ${(props) => (props.isNavOpen ? "#000" : "##C458E1")};
  }

  ${({ theme }) => theme.mediaQueries.tablet} {
    background-color: ${theme.colors.hype};
    color: ${theme.colors.black};

    &:hover {
      color: black;
      background-color: #6c3cce;
    }
  }
`;

const SignUpButton = styled.a<{ isNavOpen?: boolean }>`
  &:hover {
    color: ${(props) => (props.isNavOpen ? "#6c3cce" : "#000")};
    background-color: ${(props) => (props.isNavOpen ? "#000" : "##C458E1")};
  }

  ${({ theme }) => theme.mediaQueries.tablet} {
    background-color: ${theme.colors.hype};
    color: ${theme.colors.black};

    &:hover {
      color: black;
      background-color: #6c3cce;
    }
  }
`;

const MENU_OPEN = "open";
const MENU_CLOSED = "closed";
const MENU_PATH_PROPS = {
  initial: MENU_OPEN,
  fill: "transparent",
  strokeWidth: "2",
  stroke: "currentColor",
};

// state based colors for the global nav elements
const MENU_TEXT_VARIANTS = {
  [MENU_OPEN]: { color: "#000000" },
  [MENU_CLOSED]: { color: "#ffffff" },
};

/**
 * Toggleable menu component
 * Animates from two bars into an X based on isNavOpen
 */
interface MenuToggleProps {
  toggleNav: () => void;
  isNavOpen?: boolean;
}

export const MenuToggle: FC<MenuToggleProps> = ({ toggleNav, isNavOpen }) => (
  <motion.button
    onClick={toggleNav}
    variants={MENU_TEXT_VARIANTS}
    animate={isNavOpen ? MENU_OPEN : MENU_CLOSED}
    aria-label={isNavOpen ? "close menu" : "open menu"}
    className="nav-menu-button"
  >
    <svg width="36" height="32" viewBox="0 0 36 32">
      <motion.path
        {...MENU_PATH_PROPS}
        animate={isNavOpen ? MENU_CLOSED : MENU_OPEN}
        transition={{ type: "tween" }}
        variants={{
          [MENU_OPEN]: { d: "M 5,10 L 31,10" },
          [MENU_CLOSED]: { d: "M 5,5 L 31,31" },
        }}
      />
      <motion.path
        {...MENU_PATH_PROPS}
        animate={isNavOpen ? MENU_CLOSED : MENU_OPEN}
        transition={{ type: "tween" }}
        variants={{
          [MENU_OPEN]: { d: "M 5,22 L 31,22" },
          [MENU_CLOSED]: { d: "M 5,31 L 31,5" },
        }}
      />
    </svg>
  </motion.button>
);

interface SiteHeaderProps {
  hideHeader?: boolean;
  menuHandler?: (isNavOpen: boolean) => void;
  signupHandler?: () => void;
}

const MobileMenuToggle = styled.div(({ theme }) => ({
  display: "block",
  marginLeft: "10px",
  visibility: "visible",

  [theme.mediaQueries.tablet]: {
    display: "none",
  },
}));

const SiteHeader: FC<SiteHeaderProps> = ({
  hideHeader = false,
  menuHandler,
}) => {
  const [isNavOpen, setNavOpen] = useState<boolean>(false);
  const [{ permissions, isLoading }, account] = useAccount();
  const { handleStart: logoutStart } = useAsync();
  const { replace } = useRouter();

  const toggleNav = (newVal?: boolean): void =>
    setNavOpen((oldVal) => (typeof newVal === "boolean" ? newVal : !oldVal));

  useEffect(() => {
    const closeNav = () => setNavOpen(false);
    router.events.on("routeChangeComplete", closeNav);
    return () => {
      router.events.off("routeChangeComplete", closeNav);
    };
  }, []);

  useEffect(() => {
    menuHandler?.(isNavOpen);
  }, [isNavOpen, menuHandler]);

  const renderBetaFlag = (): ReactNode => (
    <BetaLink
      href="/faq"
      ariaLabel="beta"
      trackAction={Action.CLICKED_NAV_BETA}
      trackLabel={Label.NAV}
    >
      <BetaText>
        <BetaFlag />
      </BetaText>
      <BetaQuestionMark>
        <QuestionMark />
      </BetaQuestionMark>
    </BetaLink>
  );

  const renderUser = (): ReactNode => {
    // Show signup button if user is not logged in
    if (!permissions.canAccessAccount) {
      return (
        <Link href="/login">
          <LogInOutText isNavOpen={isNavOpen}>Log In</LogInOutText>
        </Link>
      );
    }
  };

  const zIndex = isNavOpen ? zIndices.nav + 2 : zIndices.nav;
  usePreventGlobalScrolling(isNavOpen);
  return (
    <>
      <div className="nav" style={getIsFirstLoad() ? {} : { zIndex }}>
        <div className="container">
          <div className="nav__lockup">
            <LogoLink
              href="/"
              trackAction={Action.CLICKED_NAV_LOGO}
              trackLabel={Label.NAV}
            >
              <motion.span
                className="nav__logo"
                variants={MENU_TEXT_VARIANTS}
                animate={isNavOpen ? MENU_OPEN : MENU_CLOSED}
              >
                <LogoLockupStacked variant={isNavOpen ? "black" : "white"} />
              </motion.span>
            </LogoLink>
            {renderBetaFlag()}
          </div>
          {!hideHeader && (
            <>
              <TopNav />
              <>
                <TopRightButtons>
                  {renderUser()}
                  <SignUpContainer>
                    {permissions.canAccessAccount ? (
                      <div className={styles.dropdown}>
                        <>
                          <LogContainer>
                            <Link href="/members/account">
                              <AccountButton
                                className="action_btn pill_btn"
                                isNavOpen={isNavOpen}
                              >
                                <span>Account</span>
                              </AccountButton>
                            </Link>
                          </LogContainer>
                          <div className={styles.dropdowncontent}>
                            <button
                              className="logout_btn"
                              onClick={async () => {
                                logoutStart();
                                if (!isLoading) {
                                  await account.signOut();
                                  replace("/");
                                }
                              }}
                            >
                              <LogInOutText isNavOpen={isNavOpen}>
                                Log Out
                              </LogInOutText>
                            </button>
                          </div>
                        </>
                      </div>
                    ) : (
                      <Link href="/signup">
                        <SignUpButton
                          className="action_btn pill_btn"
                          isNavOpen={isNavOpen}
                        >
                          <span>Sign Up</span>
                        </SignUpButton>
                      </Link>
                    )}
                  </SignUpContainer>

                  <MobileMenuToggle>
                    <MenuToggle toggleNav={toggleNav} isNavOpen={isNavOpen} />
                  </MobileMenuToggle>
                </TopRightButtons>

                <AnimatePresence>
                  {isNavOpen && (
                    <motion.div
                      className="nav__cover"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                    >
                      <MainNav nav />
                      <div className="nav__cover__submenu">
                        <NavFooter />
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default SiteHeader;
