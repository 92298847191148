import { Action, Purchase } from "./tracking";
import { debugLogger } from "./debugLogger";
import mixpanelBrowser from "mixpanel-browser";
const debug = debugLogger("mixpanel");

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type MixpanelProperties = Record<string, any>;

interface Mixpanel {
  init: (token: string) => undefined;
  identify: (userId: string) => void;
  people: {
    set: (
      properties: Record<
        string,
        string | number | Date | string[] | number[] | Date[]
      >
    ) => void;
  };
  register(properties: MixpanelProperties): void;
  track: (event: string, properties: MixpanelProperties) => void;
  reset: () => void;
}

const nullMixpanel: Mixpanel = {
  init: (_token) => {
    debug("Mixpanel initialized");
    return undefined;
  },
  identify: (userId) => {
    debug(`Mixpanel identify: ${userId}`);
  },
  people: {
    set: (properties) => {
      debug("Mixpanel set people properties:", properties);
    },
  },
  track: (event, properties) => {
    debug(`Mixpanel track: ${event}`, properties);
  },
  reset: () => {
    debug(`Mixpanel reset`);
  },
  register: (properties) => {
    debug(`Mixpanel register:`, properties);
  },
};

let instance: Mixpanel = nullMixpanel;

export const mixpanel = {
  init: (token?: string) => {
    if (token) {
      instance = mixpanelBrowser;
      return instance.init(token);
    }

    return instance.init("null");
  },

  identify: (userId: string) => {
    instance.identify(userId);
  },

  setProperties: (
    properties: Record<
      string,
      string | number | Date | string[] | number[] | Date[]
    >
  ) => {
    instance.people.set(properties);
  },

  track: (action: string, label?: string, category?: string) => {
    instance.track(action, { category, label });
  },

  experimentStarted: (name: string, variant: string) => {
    instance.track(Action.EXPERIMENT_STARTED, { name, variant });
  },

  viewedPage: (path: string, isInitial: boolean) => {
    instance.track(Action.VIEWED_PAGE, { pagePath: path, isInitial });
  },

  setSuperProperties: (properties: MixpanelProperties) => {
    instance.register(properties);
  },

  purchase: ({
    currency = "USD",
    name,
    price,
    quantity = 1,
    type,
  }: Purchase) => {
    instance.track("purchase", {
      currency,
      category: type,
      name,
      price,
      quantity,
      value: quantity * price,
    });
  },

  trackWithData: (action: string, data: Record<string, unknown>) => {
    instance.track(action, data);
  },

  reset: () => {
    instance.reset();
  },
};
