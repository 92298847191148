import {
  computeClockSkew,
  computeServerNow,
  computeTimeIntoLive,
  computeTimeUntilLive,
  computeTimeUntilUpcoming,
  computeUpcomingStatusKind,
} from ".";
import { DateTime } from "luxon";
import { getLive } from "../api/video";
import { LiveStatus } from "../live-status";

export const getLiveStatus = async (): Promise<LiveStatus> => {
  // Make a live API request
  const response = await getLive();

  if (response.ok) {
    const lr = response.data;
    const clockSkew = computeClockSkew(lr);
    const serverNow = computeServerNow(clockSkew);

    switch (lr.status) {
      case "live": {
        const timeIntoLive = computeTimeIntoLive(lr, serverNow);
        const timeUntilTransition = computeTimeUntilUpcoming(lr, serverNow);
        return {
          ...lr,
          status: "live",
          clockSkew,
          timeIntoLive,
          timeUntilTransition,
        };
      }

      case "upcoming": {
        const timeUntilLive = computeTimeUntilLive(lr, serverNow);
        return {
          ...lr,
          status: "upcoming",
          clockSkew,
          timeUntilLive,
          timeUntilTransition: timeUntilLive,
          upcomingStatus: computeUpcomingStatusKind(lr, timeUntilLive),
        };
      }

      case "none":
        return { status: "none", isoNow: lr.isoNow };
    }
  } else {
    console.error("Unable to get live status: ", response.errors);
    return { status: "none", isoNow: DateTime.now().toISO() };
  }
};
