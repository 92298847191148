import firebase from "firebase/app";
// specify module imports to reduce production size
import "firebase/auth";
import "firebase/firestore";
import { createProfile } from "./api/account";
import { EmailValidator } from "commons-validator-js";
import { getVisit } from "./analytics";

/**
 * List of modes for the custom handling of Fireauth action URLs
 * https://firebase.google.com/docs/auth/custom-email-handler
 */
export const MODE_PASSWORD = "resetPassword";
export const MODE_EMAIL = "recoverEmail";
export const MODE_VERIFY = "verifyEmail";

/**
 * List of auth errors that we handle
 */
export enum AuthErrorCode {
  ERROR_EXPIRED_ACTION_CODE = "auth/expired-action-code",
  ERROR_INVALID_ACTION_CODE = "auth/invalid-action-code",
  ERROR_WEAK = "auth/weak-password",
  ERROR_NO_USER = "auth/user-not-found",
  ERROR_WRONG_PASSWORD = "auth/wrong-password",
  ERROR_DUPLICATE = "auth/email-already-in-use",
  ERROR_INVALID_EMAIL = "auth/invalid-email",
  ERROR_GENERIC = "dcgo/generic",
}

// Make sure it hasn't already been initialized
if (!firebase.apps.length) {
  firebase.initializeApp({
    apiKey: process.env.NEXT_PUBLIC_FIREBASE_PUBLIC_API_KEY,
    authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
    storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  });
}

export default firebase;

export const signOut = () => firebase.auth().signOut();

export const sendPasswordReset = (email: string) =>
  firebase.auth().sendPasswordResetEmail(email);

export const signIn = (email: string, password: string) =>
  firebase.auth().signInWithEmailAndPassword(email, password);

export const applyActionCode = (code: string) =>
  firebase.auth().applyActionCode(code);

export const confirmPasswordReset = (code: string, password: string) =>
  firebase.auth().confirmPasswordReset(code, password);

export const updateEmail = async (email: string) => {
  const user = firebase.auth().currentUser;
  if (!user) {
    throw new Error(`Unable to change email for current user`);
  }
  await user.updateEmail(email);
};

export const ensureFirebaseInitialized = () => {
  if (firebase.auth().currentUser?.uid) {
    return Promise.resolve();
  }

  return new Promise<void>((resolve) => {
    const unlisten = firebase.auth().onIdTokenChanged(() => {
      unlisten();
      resolve();
    });
  });
};

/** Grab a firebase token if there's a current user. */
export const getFirebaseAuthHeaders = async (): Promise<
  Record<string, string>
> => {
  await ensureFirebaseInitialized();
  const { currentUser } = firebase.auth();

  if (!currentUser) return {};

  return { Authorization: `Bearer ${await currentUser.getIdToken()}` };
};

export interface SignUpRequest {
  newsletter: boolean;
  email: string;
  password: string;
  firstName: string;
  lastName: string;
}

interface SignUpSuccess {
  ok: true;
}

interface SignUpFailure {
  ok: false;
  error: {
    code: string;
  };
}

export type SignUpResponse = SignUpSuccess | SignUpFailure;

export const signUp = async (
  request: SignUpRequest
): Promise<SignUpResponse> => {
  const { email, password, firstName, lastName } = request;

  const visit = getVisit();
  const emailValidator = new EmailValidator();

  if (!emailValidator.isValid(email)) {
    return {
      ok: false,
      error: {
        code: AuthErrorCode.ERROR_INVALID_EMAIL,
      },
    };
  }

  /**
   * Create fireauth user
   */
  let user: firebase.User | null;
  try {
    ({ user } = await firebase
      .auth()
      .createUserWithEmailAndPassword(email, password));
  } catch (error) {
    return {
      ok: false,
      error: {
        code: (error?.code as AuthErrorCode) || AuthErrorCode.ERROR_GENERIC,
      },
    };
  }

  if (!user) {
    return { ok: false, error: { code: AuthErrorCode.ERROR_GENERIC } };
  }

  try {
    await createProfile(user.uid, {
      email,
      firstName,
      lastName,
      ...(visit && { source: visit }),
    });
    return { ok: true };
  } catch {
    const { currentUser } = firebase.auth();
    if (currentUser) {
      // if we fail to create a firestore entry, try and delete the user
      await currentUser.delete();
    }
    return { ok: false, error: { code: AuthErrorCode.ERROR_GENERIC } };
  }
};
