import { Link as LayoutLink } from "~/components/layout";
import { OnboardingLink } from "../../onboarding/OnboardingLink";
import styled from "styled-components";

export const StyledOnboardingLink = styled(OnboardingLink)(({ theme }) => ({
  paddingTop: 4,
  paddingBottom: 5,
  marginLeft: theme.space[4],
  marginRight: theme.space[4],
  minWidth: 80,
  [theme.mediaQueries.tablet]: {
    minWidth: 95,
  },
}));

export const BetaLink = styled(LayoutLink)(({ theme }) => ({
  display: "none",

  [theme.mediaQueries.tablet]: {
    alignItems: "center",
    backgroundColor: theme.colors.hype,
    borderRadius: "5px",
    display: "flex",
    height: "30px",
    justifyContent: "center",
    marginLeft: 20,
    width: "49px",

    "&:hover, &:focus": {
      backgroundColor: theme.colors.pizzazz,
    },
  },
}));

export const BetaQuestionMark = styled.figure({
  display: "none",
  [`${BetaLink}:hover &, ${BetaLink}:focus &`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

export const BetaText = styled.figure({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  [`${BetaLink}:hover &, ${BetaLink}:focus &`]: {
    display: "none",
  },
});

export const LogoLink = styled(LayoutLink)(({ theme }) => ({
  color: theme.colors.white,
}));
