import { debugLogger } from "./debugLogger";
import { Purchase } from "./tracking";

const debug = debugLogger("gtag");

export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_TRACKING_ID;
export const GA_IRL_CONVERSION_ID =
  process.env.NEXT_PUBLIC_GA_IRL_CONVERSION_ID;

export const gtag = ((...args: Parameters<typeof window.gtag>) => {
  try {
    if (GA_TRACKING_ID && typeof window !== "undefined" && window.gtag) {
      window.gtag(...args);
    }
  } catch (error) {
    // never break on analytics
    debug(error);
  }
}) as typeof window.gtag;

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({
  action,
  category,
  label,
  value,
  variant,
  isNonInteractive = false,
}: {
  action: string;
  category?: string;
  variant?: string;
  label?: string;
  value?: number;
  isNonInteractive?: boolean;
}): void => {
  debug(`gtag event ${action} ${category} ${label} ${value || ""}`);

  gtag("event", action, {
    event_category: category,
    event_label: label,
    value,
    variant,
    non_interaction: isNonInteractive,
  });
};

export const conversion = (transactionId: string) => {
  gtag("event", "conversion", { send_to: GA_IRL_CONVERSION_ID });
};

export const purchase = ({
  currency = "USD",
  name,
  price,
  quantity = 1,
  type,
}: Purchase) => {
  gtag("event", "purchase", {
    currency,
    items: [
      {
        category: type,
        name,
        price,
        quantity,
      },
    ],
    value: quantity * price,
  });
};
