export * from "./useTracking";

export enum Category {
  LIVE = "live",
  ENGAGEMENT = "engagement",
  ECOMMERCE = "ecommerce",
  VIDEO = "video",
  EXPERIMENT = "experiment",
  IRL = "irl",
  GIFT = "gift",
}

export enum Action {
  BEGAN_ORDER = "began_order",
  CANCELLED = "cancelled",
  CLICKED_HERO_DANCE_NOW = "clicked_hero_dance_now",
  CLICKED_IRL_REDEMPTION_UPSELL = "clicked_irl_redemption_upsell",
  CLICKED_NAV_ABOUT = "clicked_topnav_about",
  CLICKED_NAV_ALLHEART = "clicked_topnav_allheart",
  CLICKED_NAV_BETA = "clicked_topnav_beta",
  CLICKED_NAV_BLOG = "clicked_topnav_blog",
  CLICKED_NAV_CLASSES_IRL = "clicked_topnav_classes_irl",
  CLICKED_NAV_CLASSES_LIVE = "clicked_topnav_classes_live",
  CLICKED_NAV_CLASSES_ON_DEMAND = "clicked_topnav_classes_on_demand",
  CLICKED_NAV_FAQ = "clicked_topnav_classes_faq",
  CLICKED_NAV_HOWTO = "clicked_topnav_howto",
  CLICKED_NAV_LEGAL_PRIVACY = "clicked_topnav_legal_privacy",
  CLICKED_NAV_LEGAL_TERMS = "clicked_topnav_legal_terms",
  CLICKED_NAV_LOGO = "clicked_topnav_logo",
  CLICKED_NAV_SHOP_GIFTING = "clicked_topnav_shop_gifting",
  CLICKED_NAV_TEACHERS = "clicked_topnav_teachers",
  CLICKED_NO_FUNDS = "clicked_no_funds",
  CLICKED_ON_DEMAND_BROWSE = "clicked_on_demand_browse",
  CLICKED_PAY_AFTER_CLASS = "clicked_pay_after_class",
  CLICKED_SIGN_UP = "clicked_sign_up",
  CLICKED_SUBSCRIBE = "clicked_subscribe",
  CLICKED_SUBSCRIBE_NO_THANKS = "clicked_subscribe_no_thanks",
  CLICKED_TICKER_SIGN_UP = "clicked_ticker_sign_up",
  COMPLETED_ORDER = "completed_order",
  CONVERTED = "converted",
  CREATED_SESSION = "created_session",
  DISMISSED = "dismissed",
  EVENT_SOLD_OUT = "irl_sold_out",
  FOLLOWED_TEACHER = "followed_teacher",
  EXPERIMENT_STARTED = "experiment_started",
  JOINED_SESSION = "joined_session",
  LEAD = "generate_lead",
  LIVE_CLASS = "live_class",
  LOADED = "loaded",
  LOGGED_IN = "login",
  LOGGED_OUT = "logged_out",
  PAUSED = "paused",
  PLAYED = "played",
  REACTED = "reacted",
  REDEEMED_GIFT_SUBSCRIPTION = "redeemed_gift_subscription",
  REGISTERED = "registered",
  RESTARTED_SUBSCRIPTION = "restarted_subscription",
  SEEKED = "seeked",
  SELECTED_OFFERING = "selected_offering",
  SHARED = "shared",
  SIGNED_UP = "sign_up",
  SUBSCRIBED = "subscribed",
  UNFOLLOWED_TEACHER = "unfollowed_teacher",
  VIDEO_SESSION_PAUSED = "session_paused",
  VIDEO_SESSION_PLAYED = "session_played",
  VIDEO_SESSION_SEEKED = "session_seeked",
  VIEWED_LIVE_PAYMENT_OPTIONS = "viewed_live_payment_options",
  VIEWED_LOG_IN_FORM = "viewed_log_in_form",
  VIEWED_ONETIME_FORM = "viewed_onetime_form",
  VIEWED_PAGE = "viewed_page",
  VIEWED_RECOMMENDED_VIDEO = "viewed_recommended_video",
  VIEWED_SIGN_UP_FORM = "viewed_sign_up_form",
  VIEWED_SUBSCRIBE_FORM = "viewed_subscribe_form",
  VIEWED_SUBSCRIPTION_INFO = "viewed_subscription_info",
}

export enum Label {
  ACCOUNT = "account",
  AFTER_CLASS = "after_class",
  GLOBAL = "global",
  HERO = "hero",
  HOME = "home",
  IRL = "irl",
  IRL_REDEMPTION = "irl_redemption",
  IRL_SUBSCRIPTION_COMBO = "irl_subscription_combo",
  LIVE = "live",
  LIVE_CLASS = "live_class",
  MEMBERS = "members",
  NAV = "nav",
  POST_LIVE = "post_live",
  SUBSCRIPTION = "subscription",
  TEACHER_CONTENT = "teacher_content",
  TEACHER = "teacher",
  TEACHERS = "teachers",
  TOGETHER = "together",
  VOD = "vod",
}
