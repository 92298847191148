/* eslint-disable camelcase */
import React from "react";
const YELP_PIXEL_ID = process.env.NEXT_PUBLIC_YELP_TRACKING_ID;
const YELP_TRACKING_ID = process.env.NEXT_PUBLIC_YELP_PIXEL_ID;
const YELP_SEGMENT_IRL_PURCHASE =
  process.env.NEXT_PUBLIC_YELP_SEGMENT_IRL_PURCHASE;

declare global {
  interface Window {
    adroll?: {
      track: (name: string, data: { segment_name: string }) => void;
    };
  }
}

export const YelpTracking = () => {
  return YELP_TRACKING_ID && YELP_PIXEL_ID ? (
    <script
      id="yelp"
      dangerouslySetInnerHTML={{
        __html: `
    adroll_adv_id = "${YELP_TRACKING_ID}";
    adroll_pix_id = "${YELP_PIXEL_ID}";
    adroll_version = "2.0";

    (function(w, d, e, o, a) {
        w.__adroll_loaded = true;
        w.adroll = w.adroll || [];
        w.adroll.f = [ 'setProperties', 'identify', 'track' ];
        var roundtripUrl = "https://s.adroll.com/j/" + adroll_adv_id
                + "/roundtrip.js";
        for (a = 0; a < w.adroll.f.length; a++) {
            w.adroll[w.adroll.f[a]] = w.adroll[w.adroll.f[a]] || (function(n) {
                return function() {
                    w.adroll.push([ n, arguments ])
                }
            })(w.adroll.f[a])
        }

        e = d.createElement('script');
        o = d.getElementsByTagName('script')[0];
        e.async = 1;
        e.src = roundtripUrl;
        o.parentNode.insertBefore(e, o);
       
    })(window, document);
    adroll.track("pageView");`,
      }}
    />
  ) : null;
};

export const yelpTag = (segment_name = YELP_SEGMENT_IRL_PURCHASE) => {
  console.log({ segment_name });
  if (
    typeof segment_name === "string" &&
    typeof window !== "undefined" &&
    window.adroll &&
    window.adroll.track
  ) {
    window.adroll.track("pageView", { segment_name });
  }
};
