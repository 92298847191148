import { Action, Label, useTracking } from "~/lib/client/tracking";
import { Link } from "../layout";
import { OnboardingState } from "./useProvideOnboarding";
import { useAccount } from "~/lib/client/account";
import { useOnboarding } from "./context";
import { useRouter } from "next/router";
import React, { FC } from "react";

export const OnboardingLink: FC<{
  exitedPath?: string;
  createdMembershipPath?: string;
  className?: string;
  action?: Action;
  label?: Label;
  variant?: "pill" | "normal";
  promotionCode?: string;
  selectedOfferingId?: string;
  onboardingOptions?: Partial<OnboardingState>;
}> = ({
  children,
  exitedPath,
  createdMembershipPath: subscribedPath,
  className,
  action,
  label,
  variant = "pill",
  promotionCode,
  selectedOfferingId,
  onboardingOptions,
}) => {
  const { beginOnboarding } = useOnboarding();
  const [{ account }] = useAccount();
  const track = useTracking();
  const { asPath } = useRouter();
  const href = account.uid ? "/subscribe" : "/signup";

  return (
    <Link
      className={className}
      variant={variant}
      href={href}
      onClick={() => {
        if (action && label) {
          track.engagement(action, label);
        }
        beginOnboarding({
          subscribedPath: subscribedPath || asPath,
          exitedPath: exitedPath || asPath,
          promotionCode: promotionCode || "",
          selectedOfferingId: selectedOfferingId || "",
          label: label || Label.GLOBAL,
          mode: "default",
          ...onboardingOptions,
        });
      }}
    >
      {children ?? (account.uid ? "Subscribe" : "Sign Up")}
    </Link>
  );
};
