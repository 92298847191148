import { Action, useTracking } from "~/lib/client/tracking";
import { Button } from "../layout";
import { EmptyAPIResponse } from "~/lib/client/api";
import { subscribeToMailingList as realSubscribeToMailingList } from "~/lib/client/api/communications";
import { useAsync } from "~/lib/client/loading";
import { useForm } from "react-hook-form";
import { Words } from "design-system";
import React from "react";
import styled from "styled-components";

const NewsLetterInput = styled.input(({ theme }) => ({
  outline: "none",
  background: "transparent",
  border: 0,
  borderBottom: `1px solid #A3A3A3`,
  "&::placeholder": { fontSize: "12px" },
  fontWeight: 900,
  fontSize: "12px",
  color: theme.colors.white,
  width: "100%",
  paddingRight: "26px",
  [theme.mediaQueries.tablet]: {
    paddingRight: theme.space[5],
  },
}));

const InputContainer = styled.div(({ theme }) => ({
  marginTop: theme.space[3],
  position: "relative",
  width: 203,
}));

const ErrorMessage = styled.div({
  color: "#ff1c00",
  fontSize: "12px",
  marginTop: "4px",
});

const SubmitButton = styled(Button)({
  position: "absolute",
  left: 180,
  color: "#A3A3A3",
  width: "10px",
});

export const NewsletterSignup = ({
  subscribeToMailingList = realSubscribeToMailingList,
}: {
  subscribeToMailingList?: (email: string) => Promise<EmptyAPIResponse>;
}) => {
  const {
    isComplete,
    isLoading,
    handleFail,
    handleStart,
    handleSuccess,
  } = useAsync();
  const tracking = useTracking();

  const { handleSubmit, setError, register, errors } = useForm();

  const subscribe = async ({ email }: { email: string }) => {
    handleStart();

    tracking.engagement(Action.LEAD);
    const response = await subscribeToMailingList(email);

    if (response.ok) {
      handleSuccess();
    } else {
      setError("email", {
        type: "manual",
        message: "Failed to subscribe",
      });
      handleFail();
    }
  };

  return (
    <div id="newsletter" className="footer_newsletter">
      {isLoading && <p>Sending...</p>}
      {isComplete && <p>Thank You!</p>}
      {!isLoading && !isComplete && (
        <form onSubmit={handleSubmit(subscribe)}>
          <Words color="newgray" variant="footerSmall">
            NEWSLETTER
          </Words>
          <InputContainer>
            <NewsLetterInput
              id="newsletter-email"
              type="email"
              name="email"
              placeholder="name@address.com"
              aria-label="Email for newsletter"
              ref={register({
                required: "Please enter an email",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Not a valid email",
                },
              })}
            />
            <SubmitButton transparent submit>
              →
            </SubmitButton>
          </InputContainer>
          {errors.email && <ErrorMessage>{errors.email.message}</ErrorMessage>}
        </form>
      )}
    </div>
  );
};
