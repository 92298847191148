import { accountContext } from "./context";
import { ErrorDisplay } from "../../../components/layout/ErrorDisplay";
import { useProvideAccount } from "./useProvideAccount";
import { useTrackUser } from "./useTrackUser";
import React, { FC } from "react";

const TrackUser = () => {
  useTrackUser();
  return null;
};
// Context Provider component that wraps your app and makes account object
// available to any child component that calls the useAccount() hook.
export const ProvideAccount: FC<{ accountStatus?: string }> = ({
  children,
  accountStatus,
}) => {
  const context = useProvideAccount(accountStatus || "anonymous");
  const [{ errorMessage }] = context;
  if (errorMessage)
    return (
      <ErrorDisplay title={errorMessage}>
        <p>
          Something went wrong on our end, not your fault! In the meantime, head
          back to the homepage to get back to dancing.
        </p>
      </ErrorDisplay>
    );

  return (
    <accountContext.Provider value={context}>
      <TrackUser />
      {children}
    </accountContext.Provider>
  );
};
