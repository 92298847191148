/**
 * @file lib/client/interval.js
 *
 * @description Provides a useInterval hook that invokes a function on a
 * regular basis.
 */
import { useEffect, useRef } from "react";

/**
 * A hook-style variant of setInterval.
 *
 * If `delay` is null, the interval will be paused.
 */
export function useInterval(callback: () => void, delay: number | null) {
  // see https://overreacted.io/making-setinterval-declarative-with-react-hooks/
  const savedCallback = useRef<() => void>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback?.current?.();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}
