import { InPersonEventApi } from "dcgo-contracts";
import {
  inPersonEventApiClient,
  InPersonEventApiClient,
} from "~/lib/client/api/inPersonEvent";
import { Label, useTracking } from "~/lib/client/tracking";
import { useAccount } from "~/lib/client/account";
import { useCallback, useEffect, useState } from "react";
import { useIdempotencyKey } from "~/components/common/useIdempotencyKey";

export type OrderPhase =
  | "pay"
  | "loading"
  | "guests"
  | "no-order"
  | "waiver"
  | "complete";

export type TicketOrder = InPersonEventApi.TicketOrder & {
  currentTotal: InPersonEventApi.TicketOrderTotals;
  quantity: number;
  paymentMethodRequired: boolean;
};

export type TicketCustomer = {
  name: string;
  email: string;
};

export interface UseProvideOrder {
  customer: TicketCustomer;
  order: TicketOrder;
  previousEventId: number | null;
  phase: OrderPhase;
  beginPurchase: (request: {
    quantity: number;
    eventId: InPersonEventApi.EventId;
  }) => Promise<void>;
  guests: InPersonEventApi.GuestContactInfo[];
  acceptWaivers: (acceptedWaivers: InPersonEventApi.Waiver[]) => void;
  cancelPurchase: () => Promise<void>;
  completePurchase: (paymentMethodId: string) => Promise<void>;
  done: () => void;
  getTimeRemaining: () => number;
  setSubscriptionOfferingId: (subscriptionOfferingId: string | null) => void;
  setGuests: (guests: InPersonEventApi.GuestContactInfo[]) => void;
  subscriptionOfferingId: string | null;
  editGuests: () => void;
  setCustomerZipCode: (customerZipCode: string) => void;
}

const emptyTotal = {
  salesTaxAmount: 0,
  price: 0,
  grandTotal: 0,
  salesTaxDescription: "Tax",
};

export const defaultOrder: TicketOrder = {
  current: {
    items: [],
    totals: {
      salesTaxAmount: 0,
      price: 0,
      grandTotal: 0,
      salesTaxDescription: "Tax",
    },
  },
  upgrades: {},

  paymentMethodRequired: false,
  currentTotal: emptyTotal,
  items: [],
  timeRemaining: 0,
  active: false,
  waiversToAccept: [],
  quantity: 0,
};

const defaultCustomer: TicketCustomer = {
  name: "",
  email: "",
};

// eslint-disable-next-line require-await
const notImplemented = async () => {
  throw new Error("missing context: useOrder");
};

export const nullUseProvideOrder: UseProvideOrder = {
  customer: defaultCustomer,
  order: defaultOrder,
  previousEventId: null,
  phase: "loading",
  guests: [],
  beginPurchase: notImplemented,
  cancelPurchase: notImplemented,
  acceptWaivers: notImplemented,
  completePurchase: () => Promise.resolve(),
  done: notImplemented,
  getTimeRemaining: () => 0,
  setSubscriptionOfferingId: () => {},
  setGuests: () => {},
  subscriptionOfferingId: null,
  editGuests: () => {},
  setCustomerZipCode: () => {},
};

export const makeUseProvideOrder = (
  api: InPersonEventApiClient,
  now = () => Date.now(),
  _useTracking = useTracking
) => (): UseProvideOrder => {
  const [
    { order, previousEventId, expiration, phase },
    setOrderState,
  ] = useState<{
    order: InPersonEventApi.TicketOrder;
    previousEventId: number | null;
    expiration: number;
    phase: OrderPhase;
  }>({
    order: defaultOrder,
    previousEventId: null,
    expiration: 0,
    phase: "loading",
  });

  const [guests, setGuestInfos] = useState<InPersonEventApi.GuestContactInfo[]>(
    []
  );
  const [customer, setCustomer] = useState<TicketCustomer>(defaultCustomer);
  const [acceptedWaivers, setAcceptedWaivers] = useState<
    InPersonEventApi.AcceptedWaiver[]
  >([]);

  const [idempotencyKey, refreshIdempotencyKey] = useIdempotencyKey();
  const [clientTransactionId, refreshClientTransactionId] = useIdempotencyKey();

  const [subscriptionOfferingId, setSubscriptionOfferingId] = useState<
    string | null
  >(null);
  const [customerZipCode, setCustomerZipCode] = useState("");

  const orderEventId = order.items[0]?.eventId || 0;

  useEffect(() => {
    refreshIdempotencyKey();
    refreshClientTransactionId();
  }, [refreshIdempotencyKey, refreshClientTransactionId, orderEventId]);

  const [
    {
      profile,
      isLoading,
      permissions: { ticketAccess },
    },
    { refresh: refreshAccount },
  ] = useAccount();

  const setPhase = (phase: OrderPhase) => {
    setOrderState((order) => ({ ...order, phase }));
  };

  const getOrderPhase = (order: InPersonEventApi.TicketOrder): OrderPhase => {
    if (!order.active) return "no-order";
    // Joshua: removing waiver phase and doing waivers on payment form
    // if (order.waiversToAccept.length > 0) return "waiver";
    return order.items[0].quantity === 1 ? "pay" : "guests";
  };

  const doOrderApi = useCallback(
    (promisedOrder: Promise<InPersonEventApi.TicketOrder>) =>
      promisedOrder.then((order) => {
        const expiration = now() + order.timeRemaining;
        setOrderState({
          order,
          previousEventId: order.items.length ? order.items[0].eventId : null,
          expiration,
          phase: getOrderPhase(order),
        });

        if (order.items.length) {
          setCustomer({
            name: `${profile.firstName} ${profile.lastName}`,
            email: profile.email || "",
          });
          setGuestInfos((guests) => {
            const expectedGuests = order.items[0].quantity - 1;
            if (expectedGuests === guests.length) return guests;

            return new Array(expectedGuests)
              .fill(0)
              .map((_, i) => guests[i] || { email: "", name: "" });
          });
        }
      }),
    [profile]
  );

  useEffect(() => {
    if (ticketAccess === "none" || isLoading) {
      setPhase("no-order");
      return;
    }

    setPhase("loading");

    doOrderApi(api.getOrder());
  }, [ticketAccess, isLoading, doOrderApi]);

  useEffect(() => {
    if (!subscriptionOfferingId || !/[0-9]{5}/.test(customerZipCode)) {
      return;
    }

    api.getOrder(customerZipCode).then((order) => {
      setOrderState((current) => ({
        ...current,
        order,
      }));
    });
  }, [subscriptionOfferingId, customerZipCode]);

  useEffect(() => {
    if (subscriptionOfferingId && ticketAccess === "member") {
      setSubscriptionOfferingId(null);
    }
  }, [ticketAccess, subscriptionOfferingId]);

  useEffect(() => {
    if (order.timeRemaining > 0) {
      const timeout = setTimeout(() => {
        setOrderState({
          order: defaultOrder,
          previousEventId,
          expiration: 0,
          phase: "no-order",
        });
      }, order.timeRemaining);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [order.timeRemaining, previousEventId]);

  const track = _useTracking();

  const beginPurchase = useCallback(
    async (request: {
      quantity: number;
      eventId: InPersonEventApi.EventId;
    }) => {
      if (subscriptionOfferingId) setSubscriptionOfferingId(null);
      await doOrderApi(api.beginPurchase(request));
    },
    [doOrderApi, subscriptionOfferingId]
  );

  const cancelPurchase = async () => {
    const cancelledOrder = await api.cancelPurchase();
    setOrderState({
      order: cancelledOrder,
      previousEventId,
      expiration: 0,
      phase: "no-order",
    });
  };

  const acceptWaivers = (acceptedWaivers: InPersonEventApi.Waiver[]) => {
    setAcceptedWaivers(
      acceptedWaivers.map((w) => ({ waiverId: w.id, checksum: w.checksum }))
    );

    const nextPhase = guests.length > 0 ? "guests" : "pay";

    setPhase(nextPhase);
  };

  const setGuests = (guests: InPersonEventApi.GuestContactInfo[]) => {
    setGuestInfos(guests);
    setPhase("pay");
  };

  const editGuests = () => {
    setPhase("guests");
  };

  const quantity = order.items[0]?.quantity || 0;

  const currentTotal =
    (subscriptionOfferingId
      ? order.upgrades[subscriptionOfferingId]?.totals
      : order.current.totals) || order.current.totals;

  const completePurchase = async (paymentMethodId: string) => {
    try {
      await api.completePurchase({
        paymentMethodId,
        idempotencyKey,
        acceptedWaivers,
        guests,
        ...{
          membership: subscriptionOfferingId
            ? { offeringId: subscriptionOfferingId }
            : undefined,
        },
      });

      track.paidForTicket({
        amount: currentTotal.price,
        transactionId: clientTransactionId,
      });

      if (subscriptionOfferingId) {
        track.subscribed({
          // TODO: ARST
          // hard-coded for now
          amount: 49,
          cycle: "month",
          label: Label.IRL_SUBSCRIPTION_COMBO,
          name: "irl_subscription_combo",
        });
      }

      setOrderState({
        order: defaultOrder,
        previousEventId,
        expiration: 0,
        phase: "complete",
      });
      setSubscriptionOfferingId(null);
      if (subscriptionOfferingId) {
        refreshAccount();
      }
    } catch (e) {
      refreshIdempotencyKey();
      throw e;
    }
  };

  const done = async () => {};

  const paymentInformationRequired =
    currentTotal.price > 0 || Boolean(subscriptionOfferingId);

  return {
    customer,
    order: {
      ...order,
      currentTotal,
      quantity,
      paymentMethodRequired: paymentInformationRequired,
    },
    previousEventId,
    phase,
    guests,
    beginPurchase,
    cancelPurchase,
    completePurchase,
    acceptWaivers,
    done,
    getTimeRemaining: () => expiration - now(),
    setSubscriptionOfferingId,
    setGuests,
    subscriptionOfferingId,
    editGuests,
    setCustomerZipCode,
  };
};

export const useProvideOrder = makeUseProvideOrder(inPersonEventApiClient);
