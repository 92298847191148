import {
  color,
  ColorProps,
  typography,
  TypographyProps,
  variant,
} from "styled-system";
import { ThemeColor } from "~/theme";
import styled from "styled-components";

export type AnchorProps = Omit<ColorProps, "color"> & {
  color?: ThemeColor;
} & TypographyProps & { variant?: string; href?: string };

// Standard <a> tag. For intra-site navigation, use <Link> component instead (which wraps this).
export const Anchor = styled.a<AnchorProps>(
  color,
  typography,
  variant({ scale: "links" })
);

Anchor.defaultProps = { variant: "normal" };
