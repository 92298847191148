import "core-js/stable";
import "proxy-polyfill/proxy.min.js";
import "~/styles/styles.scss";
import * as Sentry from "@sentry/node";
import { AnimatePresence, LazyMotion } from "~/lib/client/framer";

import { DesignSystem } from "~/components/DesignSystem";
import { PageViewTracking } from "~/components/PageViewTracking";
import { ProvideAccount } from "~/lib/client/account";
import { ProvideFollows } from "~/components/following/useFollows";
import { ProvideLiveStatus } from "~/lib/client/live/useLiveStatus";
import { ProvideOnboarding } from "~/components/onboarding/context";
import { ProvideOrder } from "~/components/irl/order/context";
import { RewriteFrames } from "@sentry/integrations";
import { SWRConfig } from "swr";
import { theme } from "../theme";
import { ThemeProvider } from "styled-components";
import { useModeQuery, usePromoCodeQuery } from "~/lib/client/useInitialQuery";
import { useTracking } from "~/lib/client/tracking";
import App, { AppContext, AppProps } from "next/app";
import nextCookies from "next-cookies";
import React, { useEffect } from "react";

const SWR_CONFIG = {
  revalidateOnFocus: false,
};

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  const integrations = [];
  const SENTRY_SERVER_ROOT_DIR = process.env.NEXT_PUBLIC_SENTRY_SERVER_ROOT_DIR;
  if (process.env.NEXT_IS_SERVER === "true" && SENTRY_SERVER_ROOT_DIR) {
    // For Node.js, rewrite Error.stack to use relative paths, so that source
    // maps starting with ~/_next map to files in Error.stack with path
    // app:///_next
    integrations.push(
      new RewriteFrames({
        iteratee: (frame) => {
          if (frame.filename) {
            frame.filename = frame.filename.replace(
              SENTRY_SERVER_ROOT_DIR,
              "app:///"
            );
            frame.filename = frame.filename.replace(".next", "_next");
          }
          return frame;
        },
      })
    );
  }
  Sentry.init({
    enabled: process.env.NODE_ENV === "production",
    ignoreErrors: [
      "ResizeObserver",
      "Object Not Found Matching Id:2", // this appears to be from outloook bots https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062
    ],
    integrations,
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    environment: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT,
  });
}

const loadFeatures = async () => (await import("~/lib/client/framer")).features;

interface GoAppProps extends AppProps {
  cookies: Record<string, string>;
}

const GoApp = ({ Component, cookies, pageProps, router }: GoAppProps) => {
  const tracking = useTracking();

  useEffect(() => {
    if (process.browser) {
      const mixpanelToken = process.env.NEXT_PUBLIC_MIXPANEL_TOKEN;

      tracking.init({ mixpanelToken });
    }
  }, [tracking]);

  usePromoCodeQuery();
  useModeQuery();

  return (
    <ThemeProvider theme={theme}>
      <DesignSystem />
      <SWRConfig value={SWR_CONFIG}>
        <ProvideLiveStatus>
          <ProvideAccount accountStatus={cookies.accountStatus || "anonymous"}>
            <ProvideFollows>
              <ProvideOnboarding>
                <PageViewTracking />
                <ProvideOrder>
                  <LazyMotion features={loadFeatures}>
                    <AnimatePresence exitBeforeEnter>
                      <Component {...pageProps} key={router.route} />
                    </AnimatePresence>
                  </LazyMotion>
                </ProvideOrder>
              </ProvideOnboarding>
            </ProvideFollows>
          </ProvideAccount>
        </ProvideLiveStatus>
      </SWRConfig>
    </ThemeProvider>
  );
};

GoApp.getInitialProps = async (appContext: AppContext) => {
  const appProps = await App.getInitialProps(appContext);
  const cookies = nextCookies(appContext.ctx);

  return { ...appProps, cookies };
};

export default GoApp;
