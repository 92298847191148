import {
  nullUseOnboarding,
  UseProvideOnboarding,
  useProvideOnboarding,
} from "./useProvideOnboarding";
import React, { createContext, FC, useContext } from "react";

export const OnboardingContext = createContext<UseProvideOnboarding>(
  nullUseOnboarding
);

export const ProvideOnboarding: FC = ({ children }) => (
  <OnboardingContext.Provider value={useProvideOnboarding()}>
    {children}
  </OnboardingContext.Provider>
);

export const useOnboarding = () => useContext(OnboardingContext);
