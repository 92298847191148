import { FontFacesStyle, FontUrls } from "design-system";
import Head from "next/head";
import React from "react";

export const DesignSystem = () => (
  <>
    <Head>
      {FontUrls.map((url, index) => (
        <link
          key={index}
          rel="preload"
          href={url}
          crossOrigin="anonymous"
          as="font"
        />
      ))}
    </Head>
    <FontFacesStyle />
  </>
);
