import { AccountApi } from "dcgo-contracts";
import { APIResponse, get, post, put } from ".";

export const retrieveAccount = (): Promise<
  APIResponse<AccountApi.RetrieveAccountResponse>
> => get("/account");

export const setSegment = (
  segment: string
): Promise<APIResponse<AccountApi.RetrieveAccountResponse>> =>
  post("/account/segment", { segment });

export const recordInfoView = (): Promise<APIResponse<void>> =>
  post("/account/info-view", {});

export const recordOnetimePayment = (
  videoId: number
): Promise<APIResponse<void>> => post(`/account/onetime-payment`, { videoId });

export const createProfile = (
  uid: string,
  user: AccountApi.CreateProfileRequest
) => post(`/account/profile/${uid}`, user);

export const updateProfile = (uid: string, user: Partial<AccountApi.Profile>) =>
  put(`/account/profile/${uid}`, user);
