type StylesObject = Record<string, unknown>;

// intersection of all array members
type IntersectionOf<A extends unknown[]> = A extends [infer T, ...infer R]
  ? T & IntersectionOf<R>
  : unknown;

/**
 *
 */
export const mapStyles = <
  Styles extends Record<string, Record<string, unknown>>[],
  Props extends Partial<
    Record<keyof IntersectionOf<Styles>, boolean | undefined>
  >
>(
  ...styles: Styles
) => (props: Props) => {
  const propNames = Object.keys(props) as (keyof Props)[];

  return styles.reduce(
    (r1, s) =>
      propNames.reduce(
        //eslint-disable-next-line @typescript-eslint/no-explicit-any
        (r2, p) => (props[p] ? { ...r2, ...s[p as any] } : r2),
        r1
      ),
    {} as StylesObject
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
  ) as any;
};
