import * as Sentry from "@sentry/node";
import { Action, Category, Label } from ".";
import { debugLogger } from "../debugLogger";
import {
  event,
  GA_TRACKING_ID,
  conversion as gaConversion,
  purchase as gaPurchase,
  gtag,
} from "~/lib/client/gtag";
import { mixpanel } from "../mixpanel";
import { useMemo } from "react";
import { yelpTag } from "../yelp";
export { Label };

const debug = debugLogger("useTracking");

// The fbq typedefs aren't great -- see this link for the real info:
// https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking/
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const fbTrack = (eventType: string, payload: any) => {
  debug("fbq track", eventType, payload);
  if (typeof fbq === "undefined") return;
  return fbq("track", eventType, payload);
};

const ttqTrack = (
  eventName: string,
  parameters?: tiktok.PixelTrackingParameters
) => {
  debug("ttq track", eventName, parameters);
  if (typeof ttq === "undefined") {
    return;
  }

  return ttq.track(eventName, parameters);
};

const accountStatusChanged = (
  accountStatus: "subscriber" | "non-subscriber" | "anonymous"
) => {
  gtag("set", "user_properties", {
    account_status: accountStatus,
  });

  mixpanel.setProperties({ userAccountStatus: accountStatus });
  mixpanel.setSuperProperties({ eventAccountStatus: accountStatus });
};

type PurchaseName =
  | "monthly_subscription"
  | "yearly_subscription"
  | "live_class"
  | "irl_class"
  | "gift_subscription"
  | "irl_subscription_combo";

export interface Purchase {
  currency?: string;
  name: PurchaseName;
  price: number;
  quantity?: number;
  type: "subscription" | "one_time" | "irl" | "gift_subscription";
}

const userIdChanged = (userId: string) => {
  if (GA_TRACKING_ID) {
    gtag("config", GA_TRACKING_ID, {
      user_id: userId,
    });
  }

  mixpanel.identify(userId);

  try {
    Sentry.setUser({ id: userId });
  } catch {}
};

const trackPurchase = (options: Purchase) => {
  mixpanel.purchase(options);

  gaPurchase(options);

  fbTrack("Purchase", {
    content_name: options.name,
    currency: "USD",
    value: options.price,
  });

  ttqTrack("CompletePayment", {
    content_category: options.type,
    content_name: options.name,
    currency: "USD",
    quantity: options.quantity,
    value: options.price,
  });
};

const trackIrlConversion = (transactionId: string) => {
  gaConversion(transactionId);
  yelpTag();
};

export const useTracking = () => {
  return useMemo(
    () => ({
      init: ({ mixpanelToken }: { mixpanelToken?: string }) => {
        mixpanel.init(mixpanelToken);
      },

      viewedPage: (path: string, isInitial = false) => {
        mixpanel.viewedPage(path, isInitial);
      },

      beganOrder: () => {
        const action = Action.BEGAN_ORDER;
        const category = Category.IRL;
        const label = Label.IRL;

        event({
          action,
          category,
          label,
        });

        mixpanel.track(action, label, category);
      },

      selectedOffering: (category: Category, offeringName: string) => {
        const action = Action.SELECTED_OFFERING;
        const label = offeringName;
        event({
          action,
          category,
          label,
        });

        mixpanel.track(action, label, category);
      },

      soldOut: () => {
        const action = Action.EVENT_SOLD_OUT;
        const label = Label.IRL;
        const category = Category.IRL;

        event({
          action,
          category,
          label,
        });

        mixpanel.track(action, label, category);
      },

      paidForGiftSubscription: ({ amount }: { amount: number }) => {
        trackPurchase({
          name: "gift_subscription",
          price: amount,
          quantity: 1,
          type: "gift_subscription",
        });
      },

      redeemedGiftSubscription: (name: string) => {
        const action = Action.REDEEMED_GIFT_SUBSCRIPTION;
        const category = Category.GIFT;
        const label = name;

        event({
          action,
          category,
          label,
        });

        mixpanel.track(action, label, category);
      },

      paidForTicket: ({
        amount,
        transactionId,
      }: {
        amount: number;
        transactionId: string;
      }) => {
        trackPurchase({
          name: "irl_class",
          price: amount,
          quantity: 1,
          type: "irl",
        });
        trackIrlConversion(transactionId);
      },

      subscribed: (subscription: {
        cycle: "month" | "year";
        amount: number;
        label: Label;
        name?: PurchaseName;
      }) => {
        let { name } = subscription;
        if (!name) {
          name =
            subscription.cycle === "month"
              ? "monthly_subscription"
              : "yearly_subscription";
        }

        const options: Purchase = {
          name,
          price: subscription.amount,
          type: "subscription",
        };

        mixpanel.purchase(options);

        gaPurchase(options);

        // this is very seat-of-the-pants
        const predictedLtv =
          subscription.cycle === "month"
            ? subscription.amount * 6
            : subscription.amount;

        fbTrack("Subscribe", {
          currency: "USD",
          predicted_ltv: predictedLtv,
          value: subscription.amount,
        });

        ttqTrack("Subscribe", {
          content_name: name,
          currency: "USD",
          value: subscription.amount,
        });

        const action = Action.SUBSCRIBED;
        const category = Category.ENGAGEMENT;
        const { label } = subscription;

        event({
          action,
          category,
          label,
        });

        mixpanel.track(action, label, category);

        accountStatusChanged("subscriber");
      },

      restartedSubscription: () => {
        const action = Action.RESTARTED_SUBSCRIPTION;
        const category = Category.ENGAGEMENT;

        event({
          action,
          category,
        });
        mixpanel.track(action, undefined, category);

        accountStatusChanged("subscriber");
      },

      registered: (tracking: { label: Label }) => {
        fbTrack("CompleteRegistration", {});
        ttqTrack("CompleteRegistration");

        const action = Action.REGISTERED;
        const category = Category.ENGAGEMENT;
        const { label } = tracking;

        event({
          action,
          category,
          label,
        });
        mixpanel.track(action, label, category);

        accountStatusChanged("non-subscriber");
      },

      cancelled: () => {
        const action = Action.CANCELLED;
        const category = Category.ECOMMERCE;
        const label = Label.SUBSCRIPTION;

        event({
          action,
          category,
          label,
        });

        mixpanel.track(action, label, category);

        accountStatusChanged("non-subscriber");
      },

      engagement: (action: Action, label?: Label) => {
        event({
          category: Category.ENGAGEMENT,
          action,
          label,
        });

        mixpanel.track(action, label, Category.ENGAGEMENT);
      },

      clicked: (action: string, label?: Label, variant: string = "") => {
        event({
          category: Category.ENGAGEMENT,
          action,
          variant,
          label,
        });

        mixpanel.track(action, label, Category.ENGAGEMENT);
      },

      experimentStarted: (experimentName: string, variantName: string) => {
        const action = Action.EXPERIMENT_STARTED;
        event({
          action,
          label: experimentName,
          variant: variantName,
        });

        mixpanel.experimentStarted(experimentName, variantName);
      },

      viewedRecommendedVideo: (data: {
        nextVideoCategory: string;
        nextAssetId: number;
        currentVideoCategory: string;
        currentAssetId: number;
      }) => {
        mixpanel.trackWithData(Action.VIEWED_RECOMMENDED_VIDEO, data);
      },

      reactedToVideo: (reactionType: string) => {
        mixpanel.track(Action.REACTED, reactionType, Category.ENGAGEMENT);
      },

      signIn: (label?: Label) => {
        event({ action: Action.LOGGED_IN, label });
        mixpanel.track(Action.LOGGED_IN, label);
      },

      signOut: () => {
        if (GA_TRACKING_ID) {
          gtag("config", GA_TRACKING_ID, {
            user_id: null,
          });
          mixpanel.track(Action.LOGGED_OUT);
          mixpanel.reset();
        }
      },
      accountStatusChanged,
      userIdChanged,

      followedTeacher: (label: Label, teacherSlug: string) => {
        mixpanel.track(Action.FOLLOWED_TEACHER, label, teacherSlug);
      },

      unfollowedTeacher: (label: Label = Label.GLOBAL, teacherSlug: string) => {
        mixpanel.track(Action.UNFOLLOWED_TEACHER, label, teacherSlug);
      },
    }),
    []
  );
};

export type Tracking = ReturnType<typeof useTracking>;
