import { AlertBar } from "./AlertBar";
import { Footer } from "./Footer";
import { GA_TRACKING_ID } from "~/lib/client/gtag";
import { motion } from "../../lib/client/framer";
import { ScrollFix } from "./ScrollFix";
import { YelpTracking } from "~/lib/client/yelp";
import clsx from "clsx";
import Header, { HeaderProps } from "./Header";
import React, { FC, useEffect, useState } from "react";
import Script from "next/script";
import SiteHeader from "./SiteHeader";

interface Props extends HeaderProps {
  className?: string;
  hideHeader?: boolean;
  hideFooter?: boolean;
  isLoading?: boolean;
  signupHandler?: () => void;
  ensureVisible?: boolean;
  openPlatform?: boolean | null;
}

export const Layout: FC<Props> = ({
  children,
  className,
  hideHeader,
  hideFooter,
  signupHandler,
  ensureVisible,
  openPlatform,
  ...headerProps
}) => {
  const [alertBarStatus, setAlertBarStatus] = useState(true);
  const [isOpenPlatform, setIsOpenPlatform] = useState(false);

  useEffect(() => {
    if (sessionStorage.getItem("openPlatform")) {
      setIsOpenPlatform(true);
    }
  }, []);

  return (
    <>
      <AlertBar hide={hideHeader} setAlertBarStatus={setAlertBarStatus} />
      <main className={clsx("main", className)}>
        <Header {...headerProps} />

        <ScrollFix>
          {isOpenPlatform || openPlatform ? null : (
            <SiteHeader hideHeader={hideHeader} signupHandler={signupHandler} />
          )}

          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            {children}
          </motion.div>

          {GA_TRACKING_ID && (
            <>
              <Script
                src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
              />
              <Script id="ga-init">{`window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());`}</Script>
            </>
          )}
          <>
            <YelpTracking />
          </>

          {process.env.NEXT_PUBLIC_FB_PIXEL_ID && (
            <>
              <script
                dangerouslySetInnerHTML={{
                  __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window,document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '${process.env.NEXT_PUBLIC_FB_PIXEL_ID}');
            fbq('track', 'PageView');
            `,
                }}
              />

              <noscript>
                <img
                  height="1"
                  width="1"
                  src={`https://www.facebook.com/tr?id=${process.env.NEXT_PUBLIC_FB_PIXEL_ID}&ev=PageView&noscript=1`}
                  alt=""
                />
              </noscript>
            </>
          )}
          {Boolean(process.env.NEXT_PUBLIC_TIKTOK_PIXEL_ID) && (
            <script
              dangerouslySetInnerHTML={{
                __html: `!function (w, d, t) {
                w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script");n.type="text/javascript",n.async=!0,n.src=i+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};
                ttq.load('${process.env.NEXT_PUBLIC_TIKTOK_PIXEL_ID}');
                ttq.page();
                }(window, document, 'ttq');`,
              }}
            />
          )}
          {hideFooter || isOpenPlatform || openPlatform ? null : (
            <Footer
              className={
                alertBarStatus ? "site_footer alert_visible" : "site_footer"
              }
            />
          )}
        </ScrollFix>
      </main>
    </>
  );
};
