import { APIResponse, get } from ".";
import { AssetApi } from "dcgo-contracts";

/** Get the current live (or upcoming) status of the service. */
export const getLive = (): Promise<APIResponse<AssetApi.LiveResponse>> =>
  get("/videos/live");

/** Get the current on-demand video catalog. */
export const getDemand = (): Promise<APIResponse<AssetApi.DemandResponse>> =>
  get("/videos/demand");

/** Get a video */
export const getAsset = (id: number) =>
  get<AssetApi.RetrieveAssetResponse>(`/assets/${id}`);
