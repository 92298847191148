import { Box } from ".";
import {
  color,
  ColorProps,
  SpaceProps,
  typography,
  TypographyProps,
} from "styled-system";
import { Symbol } from "design-system";
import { ThemeColor } from "../../theme";
import React, { FC } from "react";
import styled from "styled-components";

type TextProps = TypographyProps &
  SpaceProps &
  Omit<ColorProps, "color"> & { color?: ThemeColor };

export const Words = styled(Box)<TextProps>(typography, color);

export const Heading: FC<TextProps & { level?: 1 | 2 | 3 | 4 | 5 | 6 }> = ({
  children,
  level = 1,
  ...props
}) => (
  <Words as={`h${level}`} {...props}>
    {children}
  </Words>
);

export const ForwardText: FC<TextProps> = ({ children, ...props }) => (
  <Words as="span" {...props}>
    {children}&nbsp;<Symbol>→</Symbol>
  </Words>
);

export const BackText: FC<TextProps> = ({ children, ...props }) => (
  <Words as="span" {...props}>
    <Symbol>←</Symbol>&nbsp;
    {children}
  </Words>
);

export const NoSymbolText: FC<TextProps> = ({ children, ...props }) => (
  <Words as="span" {...props}>
    {children}
  </Words>
);
