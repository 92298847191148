import {
  nullUseProvideOrder,
  UseProvideOrder,
  useProvideOrder,
} from "./useProvideOrder";
import React, { createContext, FC, useContext } from "react";

export const OrderContext = createContext<UseProvideOrder>(nullUseProvideOrder);

export const ProvideOrder: FC = ({ children }) => {
  return (
    <OrderContext.Provider value={useProvideOrder()}>
      {children}
    </OrderContext.Provider>
  );
};

export const useTicketOrder = () => useContext(OrderContext);
