const colors = {
  hype: "#d2fe53",
  hypeHover: "#e8fea9",
  hypePressed: "#a9ce44",
  pizzazz: "#d439ef",
  purple: "#7437d9",
  heart: "#6E3DD1",
  orange: "#ff841f",
  darkpizzazz: "#6E0089",
  black: "#000",
  white: "#fff",
  red: "red",
  twentywhite: "rgba(255, 255, 255, 0.2)",
  thirtywhite: "rgba(255, 255, 255, 0.3)",
  halfwhite: "rgba(255, 255, 255, 0.5)",
  seventywhite: "rgba(255, 255, 255, 0.7)",
  eightywhite: "rgba(255, 255, 255, 0.8)",
  silver: "#bfbfbf",
  transparent: "transparent",
  seventyblack: "rgba(0, 0, 0, 0.7)",
  ninetyblack: "rgba(0, 0, 0, 0.9)",
  overcast: "#161616",
  darkpurple: "rgba(45,17,91,.8)",
  daydream: "#C7F0F5",
  pulse: "#FF1C00",

  // TODO: standardize our color formats, and make this general purpose
  fadedHype: (opacity: number) => `rgba(210,254,83,${opacity})`,

  /*
    perfume: "#f2a5ee",
    blue: "#2639da",
    powder: "#bcf2f6",
    red: "#ff1c00",
    darkgray: "#737373",
    lightgray: "#d6d6d6",
    oslo: "#939598",

    darkpurple80: "rgba(color.scale($purple, $lightness: -20%), 0.8)",
    mediumpurple80: "rgba(color.scale($purple, $lightness: -60%), 0.8)",
    darkpizzazz: "rgba(color.scale($pizzazz, $lightness: -40%), 0.8)",
    */
};

const breakpoints = ["801px", "1141px", "1440px", "1601px"];

const defaults = {
  link: {
    cursor: "pointer",
    ":hover, :focus": { textDecoration: "underline" },
    display: "inline-flex",
    alignItems: "center",
  },
};

const space = [
  "0px",
  "2px",
  "4px",
  "8px",
  "16px",
  "32px",
  "64px",
  "128px",
  "256px",
  "512px",
];

export const theme = {
  breakpoints,
  colors,
  fontFamilies: {
    extended: "GT America Extended, Arial, sans-serif",
    standard: "GT America, Arial, sans-serif",
    mono: "GT America Mono, Courier New, monospace",
  },
  fontSizes: [
    "12px",
    "14px",
    "16px",
    "18px",
    "20px",
    "24px",
    "32px",
    "48px",
    "54px",
    "72px",
  ],
  fontWeights: { regular: 400, medium: 500, bold: 700, black: 900 },
  gutters: {
    mobile: "16px",
    tablet: "40px",
    desktop: "48px",
  },
  links: {
    action: {
      ...defaults.link,
      textTransform: "uppercase",
      color: colors.hype,
    },
    actionSmall: {
      ...defaults.link,
      textTransform: "uppercase",
      fontSize: "12px",
      color: colors.hype,
      letterSpacing: "0.01em",
    },
    actionWhite: {
      ...defaults.link,
      textTransform: "uppercase",
      color: colors.white,
    },
    normal: {
      ...defaults.link,
      color: colors.pizzazz,
    },
    pill: {
      ...defaults.link,
      backgroundColor: colors.hype,
      borderRadius: "30px",
      color: colors.black,
      fontSize: "14px",
      fontStyle: "normal",
      minWidth: "80px",
      fontWeight: 900,
      justifyContent: "center",
      letterSpacing: "-0.01em",
      lineHeight: 1.71,
      padding: "3px 15px 4px",
      position: "relative",
      transition: "all 0.1s ease",
      whiteSpace: "nowrap",
      "& + &": {
        marginLeft: "14px",
      },
      ":hover, :focus": {
        backgroundColor: colors.hypeHover,
        textDecoration: "none",
      },
      ":active": {
        backgroundColor: colors.hypePressed,
      },
      "@media screen and (max-width: 800px)": {
        minWidth: "80px",
      },
      "@media screen and (max-width: 320px)": {
        minWidth: "50px",
        padding: {
          left: "8px",
          right: "8px",
        },
      },
    },

    primary: {
      color: colors.hype,
    },
    nav: {
      color: colors.white,
      cursor: "pointer",
      whiteSpace: "nowrap",
      "&:hover": { color: colors.hype },
    },
    none: {
      color: "inherit",
    },
  },
  space,
  mediaQueries: {
    tablet: `@media screen and (min-width: ${breakpoints[0]})`,
    desktop: `@media screen and (min-width: ${breakpoints[1]})`,
    desktopHuge: `@media screen and (min-width: ${breakpoints[2]})`,
    print: `@media print`,
  },
};

export type Theme = typeof theme;
export type ThemeColor = keyof Theme["colors"];
