import { useState } from "react";

export const getUseSessionState = (storage: Storage) => <T>(
  name: string,
  defaultValue?: T
): [T | null | undefined, (value: T | null) => void] => {
  const getDefaultState = (): T | undefined => {
    const stored = storage.getItem(name);
    if (stored) {
      return JSON.parse(stored);
    }

    if (defaultValue) {
      storage.setItem(name, JSON.stringify(defaultValue));
      return defaultValue;
    }

    return undefined;
  };

  const [value, setValue] = useState<T | null | undefined>(getDefaultState());

  const update = (updated: T | null) => {
    if (updated === null) {
      storage.removeItem(name);
    } else {
      storage.setItem(name, JSON.stringify(updated));
    }

    setValue(updated);
  };

  return [value, update];
};

export const useSessionState = process.browser
  ? getUseSessionState(sessionStorage)
  : () => [];
