import {
  AnimatePresence,
  createDomMotionComponent,
  domAnimation,
  LazyMotion,
  useMotionValue,
  useTransform,
  useViewportScroll,
} from "framer-motion";

export {
  domAnimation,
  AnimatePresence,
  useMotionValue,
  useTransform,
  useViewportScroll,
  LazyMotion,
};

export const motion = {
  a: createDomMotionComponent("a"),
  button: createDomMotionComponent("button"),
  div: createDomMotionComponent("div"),
  figure: createDomMotionComponent("figure"),
  h1: createDomMotionComponent("h1"),
  header: createDomMotionComponent("header"),
  path: createDomMotionComponent("path"),
  span: createDomMotionComponent("span"),
};

export const features = domAnimation;
