/**
 * @file time.ts
 *
 * @description Random utilities for mucking with and formatting time and time deltas.
 */
import { DateTime, Duration } from "luxon";

// basic increments
export const MILLIS_IN_SECOND = 1000;
export const SECONDS_IN_MINUTE = 60;
export const MINUTES_IN_HOUR = 60;
export const HOURS_IN_DAY = 24;
export const DAYS_IN_WEEK = 7;

// seconds rollups
export const SECONDS_IN_HOUR = 60 * SECONDS_IN_MINUTE;
export const SECONDS_IN_DAY =
  HOURS_IN_DAY * MINUTES_IN_HOUR * SECONDS_IN_MINUTE;

// millis rollups
export const MILLIS_IN_DAY = SECONDS_IN_DAY * MILLIS_IN_SECOND;
export const MILLIS_IN_MINUTE = SECONDS_IN_MINUTE * MILLIS_IN_SECOND;
export const MILLIS_IN_HOUR = SECONDS_IN_HOUR * MILLIS_IN_SECOND;

/** Expresses a time duration as a comination of days, hours, minutes, and seconds. */
export interface Countdown {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

/**
 * Given a total number of seconds, return a structure containing
 * days, hours, minutes, and seconds. (Use Math.max to prevent negative values.)
 */
export const secondsToCountdown = (seconds: number): Countdown => ({
  days: Math.max(0, Math.floor(seconds / SECONDS_IN_DAY)),
  hours: Math.max(0, Math.floor((seconds % SECONDS_IN_DAY) / SECONDS_IN_HOUR)),
  minutes: Math.max(
    0,
    Math.floor((seconds % SECONDS_IN_HOUR) / SECONDS_IN_MINUTE)
  ),
  seconds: Math.max(0, Math.floor(seconds % SECONDS_IN_MINUTE)),
});

/**
 * Given a luxon time diff structure, return a structure containing
 * days, hours, minutes, and seconds.
 */
export const diffToCountdown = (diff: Duration): Countdown =>
  secondsToCountdown(diff.as("seconds"));

/**
 * Given a luxon DateTime, return true if it happens 'today'
 * in the browser's local time.
 */
export const isDateTimeToday = (dt: DateTime): boolean => {
  const dtLocal = dt.toLocal();
  const now = DateTime.local();
  return (
    now.year === dtLocal.year &&
    now.month === dtLocal.month &&
    now.day === dtLocal.day
  );
};

/**
 * Given an ISO date/time string, return true if it happens 'today'
 * in the browser's local time.
 */
export const isISODateTimeToday = (iso: string): boolean =>
  isDateTimeToday(DateTime.fromISO(iso));

/**
 * Given a luxon DateTime, format a string describing the day *in the pacific
 * time zone*.
 */
export const getPacificDateFormatFromDateTime = (dt: DateTime): string =>
  dt.setZone("America/Los_Angeles").toFormat("L.d.yy");

/**
 * Given an ISO date/time string, format a string describing the day *in the
 * pacific time zone*.
 */
export const getPacificDateFormatFromISODateTime = (iso: string): string =>
  getPacificDateFormatFromDateTime(DateTime.fromISO(iso));

/**
 * Given a luxon DateTime, format a string describing it in both the pacific
 * and east coast time zones. We call this our "broadcast format".
 *
 * Example: "2.8.21 - 10:00 AM PST / 1:00 PM EST"
 */
export const getBroadcastFormatFromDateTime = (dt: DateTime): string => {
  const pacific = dt.setZone("America/Los_Angeles").toFormat("t ZZZZ");
  const eastCoast = dt.setZone("America/New_York").toFormat("t ZZZZ");
  // we assume the date itself is consistent across pacfic and east coast
  const anyDate = getPacificDateFormatFromDateTime(dt);
  return `${anyDate} — ${pacific} / ${eastCoast}`;
};

/**
 * Given an ISO date/time string, format a string describing it in both the
 * pacific and east coast time zones. We call this our "broadcast format".
 */
export const getBroadcastFormatFromISODateTime = (iso: string): string =>
  getBroadcastFormatFromDateTime(DateTime.fromISO(iso));

/**
 * Given a Countdown structure (see above), return a string that describes it
 * in our typical countdown format: X D / X H / X M / X S. The string will:
 *
 * - Always include the most significant nonzero value
 * - Always include minutes
 * - Include seconds if showSeconds is true or the diff is < 60 seconds
 *
 * For instance:
 *
 * "3 D / 2 H / 1 M" or "27 M / 18 S" or "0 M / 22 S"
 */
export const getCountdownFormatFromCountdown = (
  countdown: Countdown,
  showSeconds: boolean = true
): string => {
  const parts = [];
  if (countdown.days) {
    parts.push(`${countdown.days} D`);
  }
  if (countdown.hours || countdown.days) {
    parts.push(`${countdown.hours} H`);
  }
  parts.push(`${countdown.minutes} M`);
  if (
    showSeconds ||
    (!countdown.days && !countdown.hours && !countdown.minutes)
  ) {
    parts.push(`${countdown.seconds} S`);
  }
  return parts.join(" / ");
};

/** Return a countdown format from a luxon diff. */
export const getCountdownFormatFromDiff = (
  diff: Duration,
  showSeconds: boolean = true
): string =>
  getCountdownFormatFromCountdown(diffToCountdown(diff), showSeconds);

export const getMinutesFromMillis = (millis: number): string => {
  const minutes = Math.floor(millis / 60000);
  const seconds = Math.floor((millis % 60000) / 1000);

  const formattedTime = `${minutes}:${seconds.toString().padStart(2, "0")}`;

  return formattedTime;
};
