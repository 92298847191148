import { Anchor, AnchorProps } from "./Anchor";
import React from "react";

const ExternalLink: React.FC<AnchorProps> = (props) => (
  <Anchor target="_blank" rel="noreferrer" {...props}>
    {props.children}
  </Anchor>
);

export default ExternalLink;
