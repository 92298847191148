import { Box } from ".";
import { SpaceProps } from "styled-system";
import React, { FC } from "react";

export const Buttons: FC<SpaceProps> = (props) => (
  <Box
    width={1}
    display="flex"
    justifyContent="space-between"
    alignItems="center"
    {...props}
  />
);
