import { useRouter } from "next/router";

export const useHash = (): string | undefined => {
  const { asPath } = useRouter();
  const [, hash] = asPath.split("#");
  return hash;
};

export const toHashId = (input: string): string => {
  return input
    .toLowerCase()
    .replace(/[^\w ]/g, "")
    .replace(/ /g, "-");
};

export const parseQuery = (): Record<string, string> => {
  const query = window.location.search.substring(1);
  const pairs = query.split("&");
  const result: Record<string, string> = {};
  pairs.forEach((pair) => {
    const [key, value] = pair.split("=");
    result[decodeURIComponent(key)] = decodeURIComponent(value);
  });
  return result;
};
