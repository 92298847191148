import { getFirebaseAuthHeaders } from "~/lib/client/firebase";

/** Alter the URL, as necessary, to fetch from the VOD endpoint. */
export const getDefaultAPIConfig = (url: string): { url: string } => ({
  url: `${process.env.NEXT_PUBLIC_DCVOD_API_URL}${url}`,
});

/** Return any necessary headers for all API requests. */
export const getDefaultRequestHeaders = (): Promise<Record<string, string>> =>
  getFirebaseAuthHeaders();
