import React, { FC } from "react";

export const NavFooter: FC = () => (
  <footer className="footer">
    <div className="copyright">
      <span>&copy;{new Date().getFullYear()} Dance Church&reg;.</span>
      <span>Every Body, All Heart.</span>
    </div>
  </footer>
);
