import { LEGAL_NAME } from "../../lib/shared/constants";
import Head from "next/head";
import React from "react";

export interface HeaderProps {
  title: string;
  description: string;
  imagePath?: string;
  canonicalPath?: string;
}

const Header: React.FC<HeaderProps> = ({
  title,
  description,
  imagePath,
  canonicalPath,
}) => {
  title = `${title} | ${LEGAL_NAME}`;

  return (
    <Head>
      <meta charSet="utf-8" />

      {canonicalPath && (
        <>
          <link
            rel="canonical"
            href={`${process.env.NEXT_PUBLIC_ROOT_URL}${canonicalPath}`}
          />
          <meta
            property="og:url"
            content={`${process.env.NEXT_PUBLIC_ROOT_URL}${canonicalPath}`}
          />
        </>
      )}

      {/* Responsive */}
      <meta name="viewport" content="width=device-width, initial-scale=1" />

      {/* Performance optimizations */}
      <link
        rel="preconnect"
        href="https://www.google-analytics.com"
        crossOrigin="anonymous"
      />
      <link
        rel="preconnect"
        href="https://firestore.googleapis.com"
        crossOrigin="anonymous"
      />
      <link
        rel="preconnect"
        href="https://www.googleapis.com"
        crossOrigin="anonymous"
      />
      <link
        rel="preconnect"
        href="https://www.facebook.com"
        crossOrigin="anonymous"
      />
      <link
        rel="preconnect"
        href="https://connect.facebook.net"
        crossOrigin="anonymous"
      />
      <link
        rel="preconnect"
        href={process.env.NEXT_PUBLIC_DCVOD_API_URL}
        crossOrigin="anonymous"
      />

      <link
        rel="preconnect"
        href="//cdn.dancechurch.com"
        crossOrigin="anonymous"
      />

      {/* Icons */}
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href={`${process.env.NEXT_PUBLIC_ROOT_URL}/images/apple-touch-icon.png`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href={`${process.env.NEXT_PUBLIC_ROOT_URL}/images/favicon-32x32.png`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href={`${process.env.NEXT_PUBLIC_ROOT_URL}/images/favicon-16x16.png`}
      />

      {/* Meta */}
      <title>{title}</title>
      <meta name="twitter:title" content={title} />
      <meta property="og:title" content={title} />

      <meta name="description" content={description} />
      <meta name="twitter:description" content={description} />
      <meta property="og:description" content={description} />

      <meta
        name="twitter:image"
        content={`${process.env.NEXT_PUBLIC_ROOT_URL}${
          imagePath ?? "/images/DC-GO-Social.jpg"
        }`}
      />
      <meta
        property="og:image"
        content={`${process.env.NEXT_PUBLIC_ROOT_URL}${
          imagePath ?? "/images/DC-GO-Social.jpg"
        }`}
      />

      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />

      {/* Facebook */}
      {process.env.NEXT_PUBLIC_FACEBOOK_DOMAIN_VERIFICATION && (
        <meta
          name="facebook-domain-verification"
          content={process.env.NEXT_PUBLIC_FACEBOOK_DOMAIN_VERIFICATION}
        />
      )}

      {/* Open Graph */}
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />

      <link
        type="text/plain"
        rel="author"
        href="https://go.dancechurch.com/humans.txt"
      />
    </Head>
  );
};

export default Header;
