import {
  background,
  BackgroundProps,
  border,
  BorderProps,
  color,
  ColorProps,
  flexbox,
  FlexboxProps,
  grid,
  GridProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  textAlign,
  TextAlignProps,
} from "styled-system";
import styled from "styled-components";

export type BoxProps = LayoutProps &
  SpaceProps &
  PositionProps &
  BorderProps &
  ColorProps &
  FlexboxProps &
  GridProps &
  BackgroundProps &
  TextAlignProps;

export const Box = styled.div<BoxProps>(
  space,
  color,
  border,
  position,
  layout,
  flexbox,
  grid,
  background,
  textAlign
);
