import { destroy, get, put, resolveApiResponse } from "~/lib/client/api";
import { FollowingApi } from "dcgo-contracts";

export const followApiClient = {
  getFollows: () =>
    resolveApiResponse<FollowingApi.GetFollowsReponse>(get("/follows")),

  followTeacher: (slug: string) =>
    resolveApiResponse(put(`/follows/${slug}`, {})),

  unfollowTeacher: (slug: string) =>
    resolveApiResponse(destroy(`/follows/${slug}`)),
};

export type FollowApiClient = typeof followApiClient;
