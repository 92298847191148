import React, { useContext, useEffect, useMemo, useState } from "react";
import styles from "./ScrollFix.module.scss";

const ScrollFixContext = React.createContext({
  mount: () => {},
  unmount: () => {},
});

export const usePreventGlobalScrolling = (noScroll = true) => {
  const controller = useContext(ScrollFixContext);
  useEffect(() => {
    if (noScroll) {
      controller.mount();
      return controller.unmount;
    }
  }, [controller, noScroll]);
};

export const ScrollFix: React.FC = ({ children }) => {
  const [modalCount, setModalCount] = useState(0);
  const controller = useMemo(
    () => ({
      mount: () => setModalCount((n) => n + 1),
      unmount: () => setModalCount((n) => n - 1),
    }),
    []
  );

  const anyModals = modalCount > 0;

  useEffect(() => {
    const body: HTMLElement = document.getElementsByTagName("body")[0];
    if (anyModals) {
      body.classList.add(styles.NoScroll);
    } else {
      body.classList.remove(styles.NoScroll);
    }
  }, [anyModals]);

  return (
    <ScrollFixContext.Provider value={controller}>
      {children}
    </ScrollFixContext.Provider>
  );
};
