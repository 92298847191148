import { AssetApi } from "dcgo-contracts";
import { DateTime, Duration } from "luxon";
import { isISODateTimeToday } from "../time";
import { UpcomingStatusKind } from "../live-status";
/**
 * Returns clock skew from a LiveStatus with a server time, else null.
 *
 * The clock skew is the difference between our browser's notion of "now"
 * and the server's notion of now. It can be re-used as our browser's
 * clock advances to compute an approximation of server time, without
 * repeatedly re-querying the server itself.
 *
 * This should only be called immediately after a server fetch.
 */
export const computeClockSkew = (lr: AssetApi.LiveResponse) =>
  DateTime.utc().diff(DateTime.fromISO(lr.isoNow));

/**
 * Use the clock skew to compute an approximation of the server time,
 * without actually querying the server for it. Return null if the server
 * now can't be directly computed.
 */
export const computeServerNow = (clockSkew: Duration) =>
  DateTime.utc().minus(clockSkew);

/** Computes a luxon diff value if LiveStatus is LIVE, else null. */
export const computeTimeIntoLive = (
  lr: AssetApi.LiveVideoResponse,
  now: DateTime
) => now.diff(DateTime.fromISO(lr.isoStarted));

/** Amount of time to allow for player delays before moving out of 'live' status */
const ENDING_GRACE_TIME = { seconds: 10 };

/** Computes the amount of time until we transition out of live, or null. */
export const computeTimeUntilUpcoming = (
  lr: AssetApi.LiveVideoResponse,
  now: DateTime
) => DateTime.fromISO(lr.isoEnding).plus(ENDING_GRACE_TIME).diff(now);

/** Computes a luxon diff value if LiveStatus is UPCOMING, else null. */
export const computeTimeUntilLive = (
  lr: AssetApi.UpcomingVideoResponse,
  now: DateTime
) => DateTime.fromISO(lr.isoStarting).diff(now);

/** Computes the current UpcomingStatusKind, or null. */
export const computeUpcomingStatusKind = (
  lr: AssetApi.UpcomingVideoResponse,
  timeUntilLive: Duration
): UpcomingStatusKind => {
  if (!isISODateTimeToday(lr.isoStarting)) {
    return "later";
  } else if (timeUntilLive.as("hours") >= 1) {
    return "today";
  } else {
    return "soon";
  }
};
