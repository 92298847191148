import * as Sentry from "@sentry/node";
import { useAccount } from ".";
import { useEffect, useState } from "react";

export const useTrackUser = process.browser
  ? (sentry = Sentry) => {
      const [configuredUser, setConfiguredUser] = useState(false);
      const [
        {
          account: { uid },
        },
      ] = useAccount();

      useEffect(() => {
        if (uid && !configuredUser) {
          sentry.configureScope((scope) => scope.setUser(null));
          setConfiguredUser(true);
        } else if (!uid && configuredUser) {
          console.debug("clear sentry user");
          sentry.configureScope((scope) => scope.setUser(null));
          setConfiguredUser(false);
        }
      }, [uid, configuredUser, sentry]);
    }
  : () => {};
