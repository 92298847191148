import { Action, Label } from "~/lib/client/tracking";
import { Box, Words } from "design-system";
import { Link } from ".";
import { useAccount } from "~/lib/client/account";
import { useGiftingFeature } from "~/lib/client/features/useGiftingFeature";
import { useLiveStatus } from "~/lib/client/live/useLiveStatus";
import { useRouter } from "next/router";
import clsx from "clsx";
import React from "react";
import styled from "styled-components";

const MenuSection = styled.li({
  borderTop: "1px solid #000",
  paddingBottom: "10px",
});

const StyledLink = styled(Link)<{ highlight?: boolean }>(
  ({ theme, highlight }) => ({
    display: "inline",
    fontSize: "36px",
    lineHeight: "28.65px",
    letterSpacing: "-0.65px",
    color: highlight ? theme.colors.fadedHype(0.85) : theme.colors.black,
    "&:hover": { textDecoration: "none" },
    "&:focus": { textDecoration: "none" },
  })
);

const ListItem = styled.li({
  marginBottom: "9px",
});

const TopItem = styled.li({
  marginBottom: "20px",
  lineHeight: "1",
});

const MoreText = styled(Words)({
  marginBottom: "3px",
});

const MainNav: React.FC<{ nav?: boolean }> = ({ nav }) => {
  const [{ permissions }] = useAccount();
  const liveStatus = useLiveStatus();
  const { asPath } = useRouter();
  const giftingEnabled = useGiftingFeature();

  const routeMatches = (prefix: string) => asPath.startsWith(prefix);

  return (
    <nav
      className={clsx("nav__cover__menu site_nav", {
        menu_version: nav,
      })}
    >
      <Box pt={"85px"} width={"90%"}>
        <ul>
          <MenuSection />
          <ListItem>
            <Words variant="footerSmall" color="black">
              ONLINE
            </Words>
            <StyledLink
              href={
                liveStatus.status === "live" ||
                (liveStatus.status === "upcoming" &&
                  liveStatus.upcomingStatus === "soon")
                  ? "/live"
                  : "/live-class"
              }
              highlight={routeMatches("/live")}
              trackAction={Action.CLICKED_NAV_CLASSES_LIVE}
              trackLabel={Label.NAV}
            >
              <span className="title">Live Class</span>
              <span className="tooltip">Join Us Live</span>
            </StyledLink>
          </ListItem>
          <TopItem>
            <StyledLink
              href="/on-demand"
              highlight={routeMatches("/on-demand")}
              trackAction={Action.CLICKED_NAV_CLASSES_ON_DEMAND}
              trackLabel={Label.NAV}
            >
              <span className="title">On-Demand</span>
              <span className="tooltip">
                {permissions.canAccessVOD
                  ? "Your Library"
                  : "Dance On Your Schedule"}
              </span>
            </StyledLink>
          </TopItem>
          <MenuSection />
          <Words variant="footerSmall" color="black">
            IRL
          </Words>
          <TopItem>
            <StyledLink
              href="/irl"
              highlight={routeMatches("/irl")}
              trackAction={Action.CLICKED_NAV_CLASSES_IRL}
              trackLabel={Label.NAV}
            >
              <span className="title">In-Person</span>
              <span className="tooltip">Join Us IRL</span>
            </StyledLink>
          </TopItem>
          <MenuSection />
          <Words variant="footerSmall" color="black">
            TEACHERS
          </Words>
          <ListItem>
            <StyledLink
              href="/teachers"
              highlight={routeMatches("/teachers")}
              trackAction={Action.CLICKED_NAV_TEACHERS}
              trackLabel={Label.NAV}
            >
              Current
            </StyledLink>
          </ListItem>
          <TopItem>
            <StyledLink
              href="/become-a-teacher"
              highlight={routeMatches("/become-a-teacher")}
              trackAction={Action.CLICKED_NAV_TEACHERS}
              trackLabel={Label.NAV}
            >
              Apply
            </StyledLink>
          </TopItem>
          <MenuSection />
          <MoreText variant="footerSmall" color="black">
            MORE
          </MoreText>
          <ListItem>
            <StyledLink href="https://shop.dancechurch.com/">Merch</StyledLink>
          </ListItem>
          {giftingEnabled && (
            <ListItem>
              <StyledLink
                href={"/gifts/subscription"}
                trackAction={Action.CLICKED_NAV_SHOP_GIFTING}
                trackLabel={Label.NAV}
              >
                Gifting
              </StyledLink>
            </ListItem>
          )}
          <ListItem>
            <StyledLink
              href="/blog"
              highlight={routeMatches("/blog")}
              trackAction={Action.CLICKED_NAV_BLOG}
              trackLabel={Label.NAV}
            >
              Blog
            </StyledLink>
          </ListItem>
          <ListItem>
            <StyledLink
              href="/about"
              highlight={routeMatches("/about")}
              trackAction={Action.CLICKED_NAV_ALLHEART}
              trackLabel={Label.NAV}
            >
              About
              <span className="tooltip">Community + Commitment</span>
            </StyledLink>
          </ListItem>
        </ul>
      </Box>
    </nav>
  );
};

export default MainNav;
