import { useRouter as realUseRouter } from "next/router";
import { useCallback, useEffect, useState } from "react";

type QueryProvider = () => {
  query: Record<string, string | string[] | undefined>;
};

export const getUseInitialQuery = (
  useRouter: QueryProvider,
  sessionStorage: Storage
) => (parameterName: string) => {
  const { query } = useRouter() || { query: {} };
  const [value, setValue] = useState(
    sessionStorage.getItem(parameterName) || ""
  );

  const parameterValue = query[parameterName];
  useEffect(() => {
    if (typeof parameterValue === "string") {
      sessionStorage.setItem(parameterName, parameterValue);
      setValue(parameterValue);
    }
  }, [parameterName, parameterValue]);

  const clear = useCallback(() => {
    sessionStorage.removeItem(parameterName);
    setValue("");
  }, [parameterName]);

  return { value, clear };
};

export const useInitialQuery =
  // on server, stub to return nothing
  typeof window === "undefined"
    ? () => ({ value: "", clear: () => {} })
    : getUseInitialQuery(realUseRouter, window.sessionStorage);

/**
 * preserve code query string parameter that is set upon initial
 * page load for later use during subscribe.
 */
export const usePromoCodeQuery = () => useInitialQuery("code");

/**
 * preserve beta mode query string parameter that is set upon initial
 * page load for later use checking whether beta mode is enabled
 */
export const useModeQuery = () => useInitialQuery("mode").value;

/**
 * Determines whether beta mode is enabled
 */
export const useAlphaMode = () => ({
  enabled: useModeQuery() === "alpha",
});
/**
 * Determines whether beta mode is enabled
 */
export const useBetaMode = () => {
  const mode = useModeQuery();
  return {
    enabled: mode === "beta" || mode === "alpha",
  };
};
