import {
  AccountContext,
  DEFAULT_ACTIONS,
  unauthedState,
} from "./useProvideAccount";
import { createContext } from "react";

export const accountContext = createContext<AccountContext>([
  unauthedState,
  DEFAULT_ACTIONS,
]);
