import { createVisit } from "../lib/client/analytics";
import { useAccount } from "~/lib/client/account";
import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { useTracking } from "~/lib/client/tracking";

export const PageViewTracking = () => {
  const tracking = useTracking();
  const router = useRouter();

  const [account] = useAccount();
  const [initialPageView, setInitialPageView] = useState(true);

  useEffect(() => {
    createVisit();

    const onRouteChangeComplete = (url: string) => {
      tracking.viewedPage(url);
    };

    router.events.on("routeChangeComplete", onRouteChangeComplete);
    return () =>
      router.events.off("routeChangeComplete", onRouteChangeComplete);
  }, [tracking, router.events]);

  useEffect(() => {
    if (account.isLoading || !initialPageView) return;

    tracking.viewedPage(router.asPath, true);
    setInitialPageView(false);
  }, [account.isLoading, initialPageView, router.asPath, tracking]);

  return null;
};
