import { AccountApi } from "dcgo-contracts";
import { parseQuery } from "./url";
import Cookies from "js-cookie";

const COOKIE_ANALYTICS = "analytics";
const UTM_PREFIX = "utm_";

/**
 * Gets the UTM tags from the current URL
 */
const getUtmTags = (): Record<string, string> => {
  const result: Record<string, string> = {};
  Object.entries(parseQuery()).forEach(([key, value]) => {
    if (key.startsWith(UTM_PREFIX)) {
      result[key] = value ?? "";
    }
  });
  return result;
};

/**
 * Pull a visit, if any, from the cooking for use in segmentation
 * This is likely done when a user creates their account
 */
export const getVisit = (): AccountApi.Visit | null => {
  const visit = Cookies.get(COOKIE_ANALYTICS);
  if (!visit) return null;
  try {
    return JSON.parse(visit);
  } catch (error) {
    console.warn("Failed to parse analytics cookie", error);
    return null;
  }
};

/**
 * Creates a visit if one is not already saved.
 * A visit currently contains the URL, referrer, and UTM tags on the landing page
 */
export const createVisit = (): AccountApi.Visit => {
  const visit = getVisit() ?? {
    ...getUtmTags(),
    url: window.location.href,
    referrer: document.referrer || undefined,
  };
  Cookies.set(COOKIE_ANALYTICS, JSON.stringify(visit));
  return visit;
};

export const updateVisit = (updates: Partial<AccountApi.Visit>) => {
  const visit = createVisit();
  Object.assign(visit, updates);
  Cookies.set(COOKIE_ANALYTICS, JSON.stringify(visit));
};
