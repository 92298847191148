import { get, post } from ".";
import { InPersonEventApi } from "dcgo-contracts";
import { resolveApiResponse } from "./resolveApiResponse";

export const getInPersonEvents = (): Promise<
  InPersonEventApi.InPersonEvent[]
> => resolveApiResponse(get("/irl/events"));

export const getInPersonEvent = (
  id: number | string | null
): Promise<InPersonEventApi.InPersonEvent> =>
  resolveApiResponse(get(`/irl/events/${id}`, {}));

export const getRedeemableTicketOrder = (
  code: string
): Promise<InPersonEventApi.RedeemableTicketOrder> =>
  resolveApiResponse(get(`/irl/tickets/${code}`));

export const redeemTicket = ({
  code,
  ...request
}: InPersonEventApi.RedeemCompRequest): Promise<void> =>
  resolveApiResponse(post(`/irl/tickets/${code}/redeem`, request));

export const getOrder = (
  customerZipCode?: string
): Promise<InPersonEventApi.TicketOrder> => {
  const query = customerZipCode ? `?customerZipCode=${customerZipCode}` : "";
  return resolveApiResponse(get(`/irl/order${query}`));
};

export const getIrlPurchase = (
  eventId: InPersonEventApi.EventId
): Promise<InPersonEventApi.IrlOrder> =>
  resolveApiResponse(get(`/irl/events/${eventId}/purchase`));

export const beginPurchase = (request: {
  eventId: InPersonEventApi.EventId;
  quantity: number;
}): Promise<InPersonEventApi.TicketOrder> =>
  resolveApiResponse(post(`/irl/order/begin`, request));

export const completePurchase = (
  request: InPersonEventApi.CompletePurchaseRequest
): Promise<InPersonEventApi.TicketOrder> =>
  resolveApiResponse(post(`/irl/order/complete`, request));

export const cancelPurchase = (): Promise<InPersonEventApi.TicketOrder> =>
  resolveApiResponse(post(`/irl/order/cancel`, null));

export const cancelPurchasedTickets = (
  eventId: InPersonEventApi.EventId
): Promise<void> =>
  resolveApiResponse(post(`/irl/events/${eventId}/cancel`, null));

export const inPersonEventApiClient = {
  getInPersonEvents,
  getOrder,
  beginPurchase,
  completePurchase,
  cancelPurchase,
};

export type InPersonEventApiClient = typeof inPersonEventApiClient;
