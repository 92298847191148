import {
  AnalyticsClickHandlerProps,
  useAnalyticsClickHandler,
} from "../analyticsClickHandler";
import { Anchor } from "./Anchor";
import { TypographyProps } from "styled-system";
import NextLink from "next/link";
import React, { FC } from "react";

export const Link: FC<
  {
    ariaLabel?: string;
    className?: string;
    href: string;
    variant?:
      | "normal"
      | "primary"
      | "action"
      | "actionWhite"
      | "actionSmall"
      | "pill"
      | "nav"
      | "none";
  } & TypographyProps &
    AnalyticsClickHandlerProps
> = ({
  ariaLabel,
  children,
  className,
  href,
  variant = "normal",
  ...props
}) => {
  return (
    <NextLink href={href} passHref={true}>
      <Anchor
        aria-label={ariaLabel}
        className={className}
        {...props}
        variant={variant}
        onClick={useAnalyticsClickHandler(props)}
      >
        {children}
      </Anchor>
    </NextLink>
  );
};
