import {
  AnalyticsClickHandlerProps,
  useAnalyticsClickHandler,
} from "../analyticsClickHandler";
import { mapStyles } from "../mapStyles";
import { theme, ThemeColor } from "~/theme";
import React from "react";
import styled from "styled-components";

type AllButtonProps = {
  pill?: undefined;
  black?: undefined;
  transparent?: undefined;
  circle?: undefined;
  big?: undefined;
  link?: undefined;
  grayCaps?: undefined;
  smallCaps?: undefined;
  underline?: undefined;
  pizzazz?: undefined;
};

type ButtonVariation<Props> = Omit<AllButtonProps, keyof Props> & Props;

export type ButtonStyleProps =
  | ButtonVariation<{
      pill: true;
      black?: boolean;
      white?: boolean;
    }>
  | ButtonVariation<{
      transparent: true;
    }>
  | ButtonVariation<{
      circle: true;
      big?: boolean;
    }>
  | ButtonVariation<{
      link: true;
      smallCaps?: boolean;
      underline?: boolean;
      nav?: boolean;
      pizzazz?: boolean;
    }>
  | ButtonVariation<{
      grayCaps: true;
    }>;

export type Props = AnalyticsClickHandlerProps &
  ButtonStyleProps & {
    disabled?: boolean;
    submit?: boolean;
    onClick?: () => void;
  };

const getColors = (
  bg: ThemeColor,
  fg: ThemeColor,
  activeBg: ThemeColor,
  activeFg: ThemeColor
) => ({
  backgroundColor: theme.colors[bg],
  color: theme.colors[fg],
  "&:hover": {
    backgroundColor: theme.colors[activeBg],
    color: theme.colors[activeFg],
    transition: "all 0.1s ease",
  },
  "&:focus": {
    backgroundColor: theme.colors[activeBg],
    color: theme.colors[activeFg],
    transition: "all 0.1s ease",
  },
});

const buttonStyles = mapStyles(
  {
    link: {
      ...getColors("transparent", "hype", "transparent", "purple"),
    },
    pill: {
      ...getColors("hype", "black", "purple", "black"),
      borderRadius: "30px",
      cursor: "pointer",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 900,
      justifyContent: "center",
      letterSpacing: "-0.01em",
      lineHeight: 1,
      minWidth: "95px",
      minHeight: "30px",
      padding: "5px 12px 6px",
      position: "relative",
      transition: "all 0.1s ease",
      whiteSpace: "nowrap",
      "& + &": {
        marginLeft: "10px",
      },
      "@media screen and (max-width: 800px)": {
        minWidth: "80px",
      },
      "@media screen and (max-width: 320px)": {
        minWidth: "50px",
        padding: {
          left: "8px",
          right: "8px",
        },
      },
    },
    circle: {
      ...getColors("hype", "black", "purple", "white"),
      borderRadius: "50%",
      height: "20px",
      lineHeight: "19px",
      width: "20px",

      svg: {
        display: "block",
        margin: "auto",
      },
    },
    grayCaps: {
      ...getColors("transparent", "halfwhite", "transparent", "hype"),
      display: "inline-block",
      fontFeatureSettings: '"ss03" on',
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: "500",
      letterSpacing: "0.01em",
      lineHeight: "1.25",
      textTransform: "uppercase",
    },
  },
  {
    big: {
      height: "50px",
      lineHeight: "auto",
      width: "50px",
    },
    black: getColors("black", "white", "seventyblack", "white"),
    white: getColors("white", "black", "halfwhite", "black"),
    smallCaps: {
      display: "inline-block",
      fontFeatureSettings: '"ss03" on',
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: "500",
      letterSpacing: "0.01em",
      lineHeight: "1.25",
      textTransform: "uppercase",
    },
    underline: {
      textDecoration: "underline",
    },
    nav: getColors("transparent", "white", "transparent", "hype"),
    pizzazz: {
      ...getColors("transparent", "pizzazz", "transparent", "pizzazz"),
      "&:hover": { textDecoration: "underline" }, // TODO: #kerfuffle
    },
  }
);

const commonButtonStyles = {
  alignItems: "center",
  border: 0,
  color: "inherit",
  cursor: "pointer",
  display: "inline-flex",
  outline: "none",
  padding: "0",

  "&:disabled": {
    backgroundColor: theme.colors.silver,
    cursor: "initial",

    "&:hover": {
      backgroundColor: theme.colors.silver,
    },
  },

  "@media print": {
    display: "none!important",
  },
};

const StyledButton = styled.button<ButtonStyleProps>((props) => ({
  ...commonButtonStyles,
  ...buttonStyles(props),
}));

export const Button: React.FC<Props> = (props) => {
  return (
    <StyledButton
      {...props}
      onClick={useAnalyticsClickHandler(props)}
      type={props.submit ? "submit" : "button"}
      disabled={props.disabled}
    >
      {props.children}
    </StyledButton>
  );
};
