import { APIErrors } from "dcgo-contracts";
import { getDefaultAPIConfig, getDefaultRequestHeaders } from "./config";

/**-----------------------------------------------------------------------
 * Generic API Return Types
 *-----------------------------------------------------------------------*/

/** A generic failure result type from any back-end API. */
export interface APIErrorResponse {
  ok: false;
  status: number;
  errors: APIErrors;
}

/** A generic success result type from any back-end API. */
export interface APISuccessResponse<T> {
  ok: true;
  status: number;
  data: T;
}

/** An arbitrary API response */
export type APIResponse<T> = APISuccessResponse<T> | APIErrorResponse;

/** An API response that either has errors, or no further data. */
export type EmptyAPIResponse = APIResponse<Record<string, unknown>>;

/**-----------------------------------------------------------------------
 * Generic API Methods (Generally speaking, avoid using these directly.)
 *-----------------------------------------------------------------------*/

/** The common HTTP verbs that we currently allow. */
type Method = "DELETE" | "GET" | "PATCH" | "POST" | "PUT";

/** Arbitrary HTTP headers */
type Headers = Record<string, string>;

/** Arbitrary query params */
type QueryDict = Record<string, string>;

/** A wrapper around `fetch` that sets headers, parses and wraps the response. */

export const getRun = (
  fetch = process.browser ? window.fetch : global.fetch,
  defaultHeaders = getDefaultRequestHeaders
) => async <T>({
  url,
  method,
  body,
  headers,
  query,
}: {
  url: string;
  method: Method;
  body?: string;
  headers?: Headers;
  query?: QueryDict;
}): Promise<APIResponse<T>> => {
  if (query) {
    const searchParams = new URLSearchParams(query).toString();
    url = `${url}?${searchParams}`;
  }

  let response: Response;
  try {
    response = await fetch(url, {
      method,
      body,
      headers: {
        ...(await defaultHeaders()),
        "Content-Type": "application/json",
        ...(headers ?? {}),
      },
      credentials: "include",
    });
  } catch (e) {
    if (process.env.NODE_ENV !== "test") {
      console.error(e);
    }
    return { ok: false, status: -1, errors: { form: [e] } };
  }

  // Convert the body data from JSON
  let responseData: T | APIErrors | null;
  try {
    responseData = await response.json();
  } catch (e) {
    console.error(e);
    responseData = null;
  }

  if (response.ok && responseData !== null) {
    return { ok: true, status: response.status, data: responseData as T };
  } else {
    const errors: APIErrors = responseData
      ? (responseData as APIErrors)
      : { form: ["Unknown error."] };
    return { ok: false, status: response.status, errors };
  }
};

const run = getRun();

/** Generic GET to an API endpoint. */
export const get = async <T>(
  url: string,
  query?: QueryDict
): Promise<APIResponse<T>> =>
  await run({
    ...getDefaultAPIConfig(url),
    method: "GET",
    query,
  });

/** Generic POST to an API endpoint. */
export const post = async <T>(
  url: string,
  data: unknown
): Promise<APIResponse<T>> =>
  await run({
    ...getDefaultAPIConfig(url),
    body: JSON.stringify(data),
    method: "POST",
  });

/** Generic PUT to an API endpoint. */
export const put = async <T>(
  url: string,
  data: unknown
): Promise<APIResponse<T>> =>
  await run({
    ...getDefaultAPIConfig(url),
    body: JSON.stringify(data),
    method: "PUT",
  });

/** Generic DELETE to an API endpoint. */
export const destroy = async <T>(
  url: string,
  data?: unknown
): Promise<APIResponse<T>> =>
  await run({
    ...getDefaultAPIConfig(url),
    body: JSON.stringify(data),
    method: "DELETE",
  });

export { resolveApiResponse } from "./resolveApiResponse";
