import { MILLIS_IN_SECOND } from "~/lib/client/time";
import { useEffect, useState } from "react";

/**
 * Hook that combines the state and events of a basic async action with user feedback
 * Keeps track of isLoading and isComplete with associated events
 * isComplete is reset after 5sec by default but a custom timeout can be passed in
 */
export const useAsync = (timeout = 5 * MILLIS_IN_SECOND) => {
  const [isComplete, setComplete] = useState(false);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;
    if (isComplete) {
      timer = setTimeout(() => setComplete(false), timeout);
    }
    return () => clearTimeout(timer);
  }, [isComplete, timeout]);

  return {
    isLoading,
    isComplete,
    handleStart: () => {
      setLoading(true);
      setComplete(false);
    },
    handleFail: () => {
      setLoading(false);
    },
    handleSuccess: () => {
      setLoading(false);
      setComplete(true);
    },
  };
};
