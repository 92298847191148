import "firebase/auth";

import { AccountApi, MembershipApi, PaymentMethodApi } from "dcgo-contracts";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { DateTime } from "luxon";
import { Label, useTracking } from "../tracking";
import {
  recordInfoView as recordInfoViewApi,
  recordOnetimePayment,
  retrieveAccount,
  setSegment as setSegmentApi,
  updateProfile as updateProfileApi,
} from "~/lib/client/api/account";
import { subscribeToMailingList } from "../api/communications";
import { wrapWithTimeout } from "../wrapWithTimeout";
import firebase, {
  applyActionCode,
  confirmPasswordReset,
  sendPasswordReset,
  signIn as signInApi,
  signOut as signOutApi,
  signUp as signUpApi,
  SignUpRequest,
  SignUpResponse,
  updateEmail,
} from "~/lib/client/firebase";

export interface AccountData {
  isLoading: boolean;
  errorMessage?: string;
  account: AccountApi.Account;
  location: AccountApi.Location;
  membership?: MembershipApi.Membership;
  profile: AccountApi.Profile;
  permissions: AccountApi.Permissions;
  paymentMethod?: PaymentMethodApi.PaymentMethod;
}

export interface AccountActions {
  applyActionCode: (code: string) => Promise<void>;
  confirmPasswordReset: (code: string, password: string) => Promise<void>;
  recordInfoView: () => void;
  recordOnetimePayment: typeof recordOnetimePayment;
  refresh: () => Promise<void>;
  setMembership: (membership: MembershipApi.Membership) => void;
  setPaymentMethod: (paymentMethod: PaymentMethodApi.PaymentMethod) => void;
  setSegment: (segment: string) => Promise<void>;
  setStripeCustomerId: (stripeCustomerId: string) => Promise<void>;
  signIn: (
    email: string,
    password: string,
    analyticsContext: Label
  ) => Promise<void>;
  signOut: () => Promise<void>;
  signUp: (request: SignUpRequest) => Promise<SignUpResponse>;
  sendPasswordReset: (email: string) => Promise<void>;
  updateProfile: (profile: Partial<AccountApi.Profile>) => Promise<void>;
}

export type AccountContext = [AccountData, AccountActions];

export const DEFAULT_ACTIONS: AccountActions = {
  applyActionCode: () => Promise.resolve(),
  confirmPasswordReset: () => Promise.resolve(),
  recordInfoView: () => {},
  recordOnetimePayment: () =>
    Promise.resolve({ ok: false, status: 500, errors: {} }),
  refresh: () => Promise.resolve(),
  setMembership: () => {},
  setPaymentMethod: () => {},
  setSegment: () => Promise.resolve(),
  setStripeCustomerId: () => Promise.resolve(),
  signIn: () => Promise.resolve(),
  signOut: () => Promise.resolve(),
  signUp: () => Promise.resolve({ ok: false, error: { code: "" } }),
  sendPasswordReset: () => Promise.resolve(),
  updateProfile: () => Promise.resolve(),
};

export const unauthedState: AccountData = {
  isLoading: true,
  profile: {},
  account: { uid: "" },
  location: { isBlocked: false },
  permissions: {
    canAccessVOD: false,
    showLivePayment: false,
    canAccessAccount: false,
    canWatchLive: false,
    ticketAccess: "none",
    canSubscribeWithFreeTrial: true,
  },
};

export const registeredState: AccountData = {
  isLoading: true,
  profile: {},
  account: { uid: "..." },
  location: { isBlocked: false },
  permissions: {
    canAccessVOD: false,
    showLivePayment: true,
    canAccessAccount: true,
    canWatchLive: true,
    ticketAccess: "public",
    canSubscribeWithFreeTrial: true,
  },
};

export const memberState: AccountData = {
  isLoading: true,
  profile: {},
  account: { uid: "..." },
  location: { isBlocked: false },
  permissions: {
    canAccessVOD: true,
    showLivePayment: false,
    canAccessAccount: true,
    canWatchLive: true,
    ticketAccess: "member",
    canSubscribeWithFreeTrial: false,
  },
};

const contextFromResponseData = (
  responseData: AccountApi.RetrieveAccountResponse
) => ({ ...responseData, isLoading: false });

export const provideAccountServices = createContext({
  firebaseAuth: firebase.auth,
  retrieveAccount,
});

const getInitialState = (accountStatus: string) => {
  if (accountStatus === "member") {
    return memberState;
  } else if (accountStatus === "registered") {
    return registeredState;
  }
  return unauthedState;
};

const getAccountStatus = (state: AccountData) => {
  if (!state.permissions.canAccessAccount) return "anonymous";
  return state.permissions.canAccessVOD ? "member" : "registered";
};

/**
 * Provider hook that creates account object and handles state
 */
export const useProvideAccount = (
  initialAccountStatus: string
): AccountContext => {
  const { firebaseAuth, retrieveAccount } = useContext(provideAccountServices);
  const track = useTracking();

  const [state, setState] = useState<AccountData>(
    getInitialState(initialAccountStatus)
  );

  const accountStatus = getAccountStatus(state);

  useEffect(() => {
    document.cookie = `accountStatus=${accountStatus}; path=/`;
  }, [accountStatus]);

  const setError = (errorMessage: string) => {
    setState({
      ...unauthedState,
      errorMessage,
      isLoading: false,
    });
  };

  const fetchAccount = useCallback(async () => {
    try {
      const response = await wrapWithTimeout(retrieveAccount(), 10000);
      if (response.ok) {
        const context = contextFromResponseData(response.data);
        if (context.account.uid === "") {
          track.accountStatusChanged("anonymous");
        } else {
          track.accountStatusChanged(
            context.permissions.canAccessVOD ? "subscriber" : "non-subscriber"
          );
          track.userIdChanged(context.account.uid);
        }
        setState(context);
        return response.data;
      } else {
        console.error(`Error fetching account: ${response.errors.form}`);
        setError("Oops. Something went wrong.");
      }
    } catch (e) {
      console.error(e);
      setError("Oops. Something went wrong.");
    }
  }, [retrieveAccount, track]);

  const waitForIdToken = useCallback(
    () =>
      new Promise<void>((resolve) => {
        const unlisten = firebaseAuth().onIdTokenChanged(() => {
          unlisten();
          resolve();
        });
      }),
    [firebaseAuth]
  );

  const ensureFirebaseInitialized = useCallback(() => {
    if (firebaseAuth().currentUser?.uid) {
      return Promise.resolve();
    }

    return waitForIdToken();
  }, [firebaseAuth, waitForIdToken]);

  const signInAnonymously = useCallback(async (): Promise<void> => {
    await firebaseAuth().signInAnonymously();
    await waitForIdToken();
  }, [firebaseAuth, waitForIdToken]);

  const mergeAccount = useCallback((updates: Partial<AccountApi.Account>) => {
    setState(
      (context): AccountData => ({
        ...context,
        account: { ...context.account, ...updates },
      })
    );
  }, []);

  const setMembership = useCallback(
    async (membership: MembershipApi.Membership) => {
      setState(
        (context): AccountData => ({
          ...context,
          membership,
        })
      );
      await fetchAccount();
    },
    [fetchAccount]
  );

  const setPaymentMethod = useCallback(
    (paymentMethod: PaymentMethodApi.PaymentMethod) => {
      mergeAccount({ paymentMethod });
    },
    [mergeAccount]
  );

  const recordInfoView = useCallback(() => {
    mergeAccount({
      uid: state?.account?.uid || "",
      lastInfoView: DateTime.local().toISO(),
    });
    recordInfoViewApi();
  }, [mergeAccount, state?.account?.uid]);

  const setSegment = useCallback(
    async (segment: string) => {
      if (!state.account?.uid) {
        await signInAnonymously();
      }

      const response = await setSegmentApi(segment);
      if (response.ok) {
        setState(contextFromResponseData(response.data));
      }
    },
    [signInAnonymously, state.account?.uid]
  );

  // TODO: analytics context
  const signIn: AccountActions["signIn"] = useCallback(
    async (email, password, analyticsContext) => {
      await signInApi(email, password);
      const response = await fetchAccount();

      if (response) {
        track.signIn(analyticsContext);
      }
    },
    [fetchAccount, track]
  );

  const signUp: AccountActions["signUp"] = useCallback(
    async (request) => {
      const response = await signUpApi(request);

      if (!response.ok) {
        return response;
      }

      const { newsletter, email } = request;

      /**
       * Add to mailing list
       */
      if (newsletter) {
        // make sure we don't break signup with a failed marketing task
        try {
          // DO NOT AWAIT THIS
          // We are going to ignore failures so it would needlessly slow signup
          subscribeToMailingList(email);
        } catch {
          console.error(`ERROR: Newsletter signup failure (${email})`);
        }
      }
      await fetchAccount();
      return response;
    },
    [fetchAccount]
  );

  const signOut = useCallback(async () => {
    await signOutApi();
    await fetchAccount();

    track.signOut();
  }, [fetchAccount, track]);

  const setStripeCustomerId = useCallback(
    async (stripeCustomerId: string) => {
      await updateProfileApi(state.account?.uid, { stripeCustomerId });
    },
    [state.account?.uid]
  );

  const updateProfile: AccountActions["updateProfile"] = useCallback(
    async (profile) => {
      if (profile.email && profile.email !== state.profile.email) {
        await updateEmail(profile.email);
      }
      await updateProfileApi(state.account?.uid, profile);
      await fetchAccount();
    },
    [fetchAccount, state.account?.uid, state.profile.email]
  );

  useEffect(() => {
    ensureFirebaseInitialized().then(fetchAccount);
  }, [ensureFirebaseInitialized, fetchAccount]);

  if (!process.browser) return [state, DEFAULT_ACTIONS];

  return [
    state,
    {
      applyActionCode,
      confirmPasswordReset,
      setSegment,
      setMembership,
      signIn,
      signUp,
      signOut,
      setPaymentMethod,
      sendPasswordReset,
      recordInfoView,
      recordOnetimePayment,
      refresh: async () => {
        await fetchAccount();
      },
      updateProfile,
      setStripeCustomerId,
    },
  ];
};
