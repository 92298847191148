import {
  computeServerNow,
  computeTimeIntoLive,
  computeTimeUntilLive,
  computeTimeUntilUpcoming,
} from ".";
import { Duration } from "luxon";
import { getLiveStatus } from "./getLiveStatus";
import {
  HOURS_IN_DAY,
  MILLIS_IN_HOUR,
  MILLIS_IN_MINUTE,
  MILLIS_IN_SECOND,
} from "../time";
import { LiveStatus, LiveStatusUpdateMode } from "../live-status";
import { useEffect, useState } from "react";
import { useInterval } from "../interval";

/**
 * Hook that enables any component to get a current "live" status.
 *
 * The hook makes a minimal number of requests to our server, but regularly
 * updates so that downstream code can build nice UI and other features.
 *
 * In particular, it exposes a "status" value which is typically one of
 * UPCOMING or LIVE. This value, and the related time fields, will update
 * automatically as we transition states.
 *
 * In addition:
 *
 * - When a video is upcoming, the .timeUntilLive contains a luxon diff
 *   describing how *much* time is remaining.
 * - When a video is live, the .timeIntoLive contains a luxon diff describing
 *   how far into the video we are.
 *
 * The hook offers two update modes: slow and fast. Slow is the default. In
 * slow mode, we update status and time diffs only near phase transitions.
 *
 * In fast mode, we update every second. This allows us to build true
 * countdown UI.
 *
 * The `test` parameter can be used to simulate a live status without
 * actually forcing the issue. You can pass in fake API responses, either in
 * single, or sequence.
 */
export const useProvideLiveStatus = (
  updateMode: LiveStatusUpdateMode = "slow"
): LiveStatus => {
  // eslint-disable-next-line no-nested-ternary
  const [liveStatus, setLiveStatus] = useState<LiveStatus>({
    status: "loading",
  });

  // fetch the live status from the API exactly once
  useEffect(() => {
    const doGetLiveStatus = async () => {
      const status = await getLiveStatus();
      setLiveStatus(status);
    };

    doGetLiveStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // compute a countdown time. the rules are a little complex:
  let countdown: number | null = null;

  if (updateMode !== "none") {
    if (liveStatus.status === "live") {
      // when there's a live video, we update every second no matter what
      countdown = MILLIS_IN_SECOND;
    } else if (liveStatus.status === "upcoming") {
      // when there's an upcoming video, we...
      if (updateMode === "fast") {
        // ...update every second in fast mode
        countdown = MILLIS_IN_SECOND;
      } else if (liveStatus.timeUntilTransition.as("hours") > HOURS_IN_DAY) {
        // ...update every hour if we're > 24 hours away
        countdown = MILLIS_IN_HOUR;
      } else if (liveStatus.timeUntilTransition.as("hours") > 1) {
        // ...update every minute if we're > 1 hour away
        countdown = MILLIS_IN_MINUTE;
      } else {
        // ...update every second if we're <= 1 hour away
        countdown = MILLIS_IN_SECOND;
      }
    }
  }

  const refreshLiveStatus = async () => {
    const status = await getLiveStatus();
    setLiveStatus(status);
  };

  // regularly recompute "isoNow" and the various countdown Durations
  useInterval(() => {
    setLiveStatus(
      (ls: LiveStatus): LiveStatus => {
        let newLiveStatus = ls;
        let timeUntilTransition: Duration | null = null;

        if (ls.status === "live") {
          const serverNow = computeServerNow(ls.clockSkew);
          const timeIntoLive = computeTimeIntoLive(ls, serverNow);
          timeUntilTransition = computeTimeUntilUpcoming(ls, serverNow);
          newLiveStatus = {
            ...ls,
            status: "live",
            isoNow: serverNow.toISO(),
            timeIntoLive,
            timeUntilTransition,
          };
        } else if (ls.status === "upcoming") {
          const serverNow = computeServerNow(ls.clockSkew);
          const timeUntilLive = computeTimeUntilLive(ls, serverNow);
          timeUntilTransition = timeUntilLive;
          newLiveStatus = {
            ...ls,
            status: "upcoming",
            isoNow: serverNow.toISO(),
            timeUntilLive,
            timeUntilTransition,
          };
        }

        if (timeUntilTransition && timeUntilTransition.as("seconds") <= 1) {
          // if we're about to transition to a new status, re-fetch from
          // our server. we hope that differences in browser times will
          // mostly avoid stampeding behavior, but we may want jitter here?
          // TODO https://github.com/Dance-Church/dcgo/issues/298
          refreshLiveStatus();
        }

        return newLiveStatus;
      }
    );
  }, countdown);

  return liveStatus;
};
