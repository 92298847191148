import { APIErrorResponse, APIResponse } from "./";

export class FailedResponse extends Error {
  constructor(response: APIErrorResponse) {
    const message = `Request failed: ${
      response.errors.form?.join(", ") || JSON.stringify(response.errors)
    }`;
    super(message);
    this.status = response.status;
  }

  status: number;
}

export const resolveApiResponse: <T>(
  responsePromise: Promise<APIResponse<T>>
) => Promise<T> = async (responsePromise) => {
  const response = await responsePromise;

  if (response.ok) return response.data;
  throw new FailedResponse(response);
};
