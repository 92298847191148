import { Action } from "~/lib/client/tracking";
import { Link } from "./Link";
import { useGiftingFeature } from "~/lib/client/features/useGiftingFeature";
import { useLiveStatus } from "~/lib/client/live/useLiveStatus";
import React, { useRef } from "react";
import styled from "styled-components";
import styles from "./TopNav.module.scss";

const Container = styled.ul(({ theme }) => ({
  display: "none",
  padding: `0 ${theme.gutters.mobile}`,

  [theme.mediaQueries.tablet]: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    minWidth: 320,
    position: "relative",
  },
}));

const NavItem = styled.li(({ theme }) => ({
  position: "relative",
  marginRight: "32px",
  ":last-child": {
    margin: 0,
  },

  [theme.mediaQueries.tablet]: {
    fontSize: "13px",
  },

  [theme.mediaQueries.desktop]: {
    fontSize: "18px",
  },
}));

const ShopMenu = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  position: "absolute",
  top: "calc(100% + 12px)",
  transform: "translate(-50%)",
  marginTop: "-15px",
  zIndex: 1,

  "&:after": {
    background: "radial-gradient(ellipse closest-side, #00000090, transparent)",
    content: "''",
    display: "block",
    position: "absolute",
    top: "0px",
    left: "-18%",
    height: "140%",
    width: "140%",
    zIndex: -1,
  },

  [theme.mediaQueries.tablet]: {
    left: "9%",
  },

  [theme.mediaQueries.desktop]: {
    left: "15%",
  },
}));

const OnlineMenu = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  left: "20%",
  position: "absolute",
  top: "calc(100% + 12px)",
  transform: "translate(-50%)",
  marginTop: "-15px",
  zIndex: 1,

  "&:after": {
    background: "radial-gradient(ellipse closest-side, #00000090, transparent)",
    content: "''",
    display: "block",
    position: "absolute",
    top: "0px",
    left: "-18%",
    height: "140%",
    width: "140%",
    zIndex: -1,
  },

  [theme.mediaQueries.tablet]: {
    left: "12%",
  },

  [theme.mediaQueries.desktop]: {
    left: "20%",
  },
}));

const MenuList = styled.ul({
  listStyle: "none",
  whiteSpace: "nowrap",
});

const MenuItem = styled.li<{ first?: boolean; last?: boolean }>(
  ({ theme }) => ({
    textAlign: "center",
    margin: "0px 0px 0px 20px",

    [theme.mediaQueries.tablet]: {
      lineHeight: 0.5,
    },

    [theme.mediaQueries.desktop]: {
      lineHeight: "initial",
    },
  })
);

const NavNewLink = styled(Link)(({ theme }) => ({
  fontFamily: "gt america",
  fontWeight: 900,
  lineHeight: "22px",
  letter: "-0.5px",

  [theme.mediaQueries.tablet]: {
    fontSize: "13px",
  },

  [theme.mediaQueries.desktop]: {
    fontSize: "18px",
  },
}));

const MenuCircle = styled.h1(({ theme }) => ({
  textAlign: "center",
  fontWeight: 900,

  [theme.mediaQueries.tablet]: {
    fontSize: "13px",
  },

  [theme.mediaQueries.desktop]: {
    fontSize: "18px",
  },
}));

export const TopNav = () => {
  const ref = useRef<HTMLDivElement>(null);
  const liveStatus = useLiveStatus();
  const liveClassHref = liveStatus.status === "live" ? "/live" : "/live-class";
  const giftingEnabled = useGiftingFeature();

  return (
    <Container>
      <NavItem>
        <div className={styles.dropdown}>
          <button className={styles.dropbtn}>Online</button>
          <div className={styles.dropdowncontent}>
            <OnlineMenu ref={ref} tabIndex={-1}>
              <MenuCircle>•</MenuCircle>
              <MenuList>
                <MenuItem>
                  <Link
                    href={liveClassHref}
                    trackAction={Action.CLICKED_NAV_CLASSES_LIVE}
                    variant="none"
                  >
                    Live Classes
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link
                    href="/on-demand"
                    trackAction={Action.CLICKED_NAV_CLASSES_ON_DEMAND}
                    variant="none"
                  >
                    On-Demand
                  </Link>
                </MenuItem>
              </MenuList>
            </OnlineMenu>
          </div>
        </div>
      </NavItem>
      <NavItem>
        <NavNewLink
          variant="nav"
          href={"/irl"}
          trackAction={Action.CLICKED_NAV_CLASSES_IRL}
        >
          In-Person
        </NavNewLink>
      </NavItem>
      {/* <NavItem>
        <NavNewLink
          variant="nav"
          href={"/teachers"}
          trackAction={Action.CLICKED_NAV_TEACHERS}
        >
          Teachers
        </NavNewLink>
      </NavItem> */}
      <NavItem>
        <div className={styles.dropdown}>
          <button className={styles.dropbtn}>Teachers</button>
          <div className={styles.dropdowncontent}>
            <OnlineMenu ref={ref} tabIndex={-1}>
              <MenuCircle>•</MenuCircle>
              <MenuList>
                <MenuItem>
                  <Link
                    href={"/teachers"}
                    trackAction={Action.CLICKED_NAV_CLASSES_LIVE}
                    variant="none"
                  >
                    Current
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link
                    href="/become-a-teacher"
                    trackAction={Action.CLICKED_NAV_CLASSES_ON_DEMAND}
                    variant="none"
                  >
                    Apply
                  </Link>
                </MenuItem>
              </MenuList>
            </OnlineMenu>
          </div>
        </div>
      </NavItem>
      <NavItem>
        <div className={styles.dropdown}>
          <button className={styles.dropbtn}>Shop</button>
          <div className={styles.dropdowncontent}>
            <ShopMenu ref={ref} tabIndex={-1}>
              <MenuCircle>•</MenuCircle>
              <MenuList>
                <MenuItem>
                  <Link href="https://shop.dancechurch.com/">Merch</Link>
                </MenuItem>
                {giftingEnabled && (
                  <MenuItem>
                    <Link
                      href="/gifts/subscription"
                      trackAction={Action.CLICKED_NAV_SHOP_GIFTING}
                    >
                      DC Gifts
                    </Link>
                  </MenuItem>
                )}
              </MenuList>
            </ShopMenu>
          </div>
        </div>
      </NavItem>
      <NavItem>
        <NavNewLink
          variant="nav"
          href="/blog"
          trackAction={Action.CLICKED_NAV_BLOG}
        >
          Blog
        </NavNewLink>
      </NavItem>

      <NavItem>
        <NavNewLink
          variant="nav"
          href={"/about"}
          trackAction={Action.CLICKED_NAV_ABOUT}
        >
          About
        </NavNewLink>
      </NavItem>
    </Container>
  );
};
