import { useCallback, useState } from "react";
import { v4 as uuid } from "uuid";
export const useIdempotencyKey = () => {
  const [key, setKey] = useState(uuid());

  return [
    key,
    useCallback(() => {
      setKey(uuid());
    }, []),
  ] as [string, () => void];
};
