import { Anchor } from "~/components/layout/Anchor";
import { useGiftingFeature } from "~/lib/client/features/useGiftingFeature";
import { useRouter } from "next/router";
import { useSessionState } from "~/lib/client/useSessionState";
import CloseIcon from "~/images/close.svg";
import React from "react";
import styled from "styled-components";
import styles from "./AlertBar.module.scss";

const RedeemedBoldText = styled.span(({ theme }) => ({
  fontWeight: theme.fontWeights.bold,
  textDecoration: "none",
}));

const BoldText = styled.span(({ theme }) => ({
  fontWeight: theme.fontWeights.bold,
  textDecoration: "underline",

  [theme.mediaQueries.tablet]: {
    textDecoration: "none",
  },
}));

const Link = styled(Anchor)(({ theme }) => ({
  color: theme.colors.black,
  display: "inline",
}));

const GiftPurchaseAlert = () => (
  <Link href="/gifts/subscription">
    <>
      <BoldText>Give the gift of dance </BoldText> with a Go membership
    </>
  </Link>
);

const RedeemedGiftAlert = () => (
  <>
    <RedeemedBoldText>Your gift is redeemed. Happy Dancing!</RedeemedBoldText>
  </>
);

type AlertBarContent = React.ComponentType<Record<string, never>>;

const contentForAlertQuery: Record<string, AlertBarContent | undefined> = {
  redeemedGift: RedeemedGiftAlert,
};

const currentAlert = "gifting";

export const AlertBar = ({
  hide,
  setAlertBarStatus,
}: {
  hide: boolean | undefined;
  setAlertBarStatus: (status: boolean) => void;
}) => {
  const [dismissedAlert, setDismissedAlert] = useSessionState<string>(
    "dismissedAlert",
    ""
  );

  const router = useRouter();

  const giftingEnabled = useGiftingFeature();

  const hideGiftingAlertBar =
    router.asPath.includes("/gifts") ||
    router.asPath.includes("/irl/") ||
    hide ||
    !giftingEnabled ||
    dismissedAlert === currentAlert;

  const alertQuery = `${router.query.alert || ""}`;

  let Content = contentForAlertQuery[alertQuery];

  if (!Content && !hideGiftingAlertBar) Content = GiftPurchaseAlert;

  if (!Content) return null;

  return (
    <div className={styles.alert_bar}>
      <button
        className={styles.close_alert_bar}
        onClick={() => {
          if (router.query.alert) {
            const {
              pathname,
              query: { alert, ...query },
            } = router;
            router.replace({ pathname, query });
          } else {
            setDismissedAlert(currentAlert);

            if (currentAlert === "gifting") {
              setAlertBarStatus(false);
            }
          }
        }}
      >
        <CloseIcon />
      </button>

      <Content />
    </div>
  );
};
