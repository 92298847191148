import { AnimatePresence, motion } from "~/lib/client/framer";
import React, { FC, useEffect } from "react";

let isFirstLoad = true;

export const getIsFirstLoad = (): boolean => isFirstLoad;

export const Loading: FC<{ visible: boolean }> = ({ visible = true }) => {
  const setLoaded = (): void => {
    isFirstLoad = false;
  };
  useEffect(() => setLoaded, []);
  useEffect(() => {
    if (isFirstLoad && !visible) {
      setLoaded();
    }
  }, [visible]);
  return (
    <AnimatePresence>
      {visible && (
        <motion.div
          className="loading"
          initial={{ opacity: isFirstLoad ? 1 : 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          {/* {isFirstLoad && <Logo />} */}
        </motion.div>
      )}
    </AnimatePresence>
  );
};
