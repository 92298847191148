export const wrapWithTimeout = async <T>(p: Promise<T>, ms: number) => {
  let timeout: ReturnType<typeof setTimeout> | null = null;
  const timeoutPromise = new Promise<T>((_, reject) => {
    timeout = setTimeout(() => {
      timeout = null;
      reject(new Error(`Timed out after ${ms} ms`));
    }, ms);
  });
  try {
    const result = await Promise.race([p, timeoutPromise]);
    if (timeout) clearTimeout(timeout);
    return result;
  } catch (e) {
    if (timeout) clearTimeout(timeout);
    throw e;
  }
};
