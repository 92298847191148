import { debugLogger } from "~/lib/client/debugLogger";
import { Label } from "~/lib/client/tracking";
import { useAccount } from "~/lib/client/account";
import { useEffect } from "react";
import { usePromoCodeQuery } from "~/lib/client/useInitialQuery";
import { useRouter } from "next/router";
import { useSessionState } from "~/lib/client/useSessionState";

const debug = debugLogger("useOnboarding");

type OnboardingMode = "default" | "gift";

/* Stored in Session State */
export interface OnboardingState {
  exitButtonText?: string;
  exitedPath: string;
  promotionCode?: string;
  subscribedPath: string;
  selectedOfferingId?: string;
  label: Label;
  mode: OnboardingMode;
}

type OnboardingResult = "exited" | "subscribed";

export interface UseProvideOnboarding {
  beginOnboarding: (request?: OnboardingState) => void;
  exitOnboarding: (result: OnboardingResult) => void;
  clearOnboarding: () => void;
  exitButtonText?: string;
  promotionCode: string;
  selectedOfferingId: string;
  label: Label;
  mode: OnboardingMode;
}

export const nullUseOnboarding: UseProvideOnboarding = {
  beginOnboarding: () => {},
  exitOnboarding: () => {},
  clearOnboarding: () => {},
  exitButtonText: "",
  promotionCode: "",
  selectedOfferingId: "",
  label: Label.GLOBAL,
  mode: "default",
};

export const useProvideOnboarding = (): UseProvideOnboarding => {
  const { asPath, replace } = useRouter();
  const [{ permissions }] = useAccount();

  const defaultState: OnboardingState & { promotionCode: string } = {
    exitButtonText: "No thanks, I'll stick with a free account",
    exitedPath: asPath,
    promotionCode: "",
    subscribedPath: "/",
    selectedOfferingId: "",
    label: Label.GLOBAL,
    mode: "default",
  };

  const [
    onboardingState,
    setOnboardingState,
  ] = useSessionState<OnboardingState | null>("ob");

  const { clear: clearSessionCode } = usePromoCodeQuery();

  /** Remove any initial promo codes if the use cannot start free trial */
  useEffect(() => {
    if (
      permissions.canAccessAccount &&
      !permissions.canSubscribeWithFreeTrial
    ) {
      const currentState = onboardingState || defaultState;
      setOnboardingState({ ...currentState, promotionCode: "" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissions.canSubscribeWithFreeTrial, permissions.canAccessAccount]);

  const beginOnboarding = (request: OnboardingState = defaultState) => {
    setOnboardingState(request);
    debug("Begin onboarding", request);
  };

  const exitOnboarding = (result: OnboardingResult) => {
    if (!onboardingState) {
      console.error(
        `Onboarding state is null! Cannot exit onboarding. result: ${result}, path: ${asPath}`
      );
      replace("/");
      return;
    }

    const giftWasRedeemed =
      onboardingState.mode === "gift" && result === "subscribed";

    const path = giftWasRedeemed
      ? `${onboardingState.subscribedPath}?alert=redeemedGift`
      : result === "exited"
      ? onboardingState.exitedPath
      : onboardingState.subscribedPath;

    console.log("Exit onboarding", result, path, onboardingState);

    replace(path).then(() => {
      setOnboardingState(null);
      if (giftWasRedeemed) {
        clearSessionCode();
      }
    });
  };

  const clearOnboarding = () => {
    setOnboardingState(null);
  };

  return {
    beginOnboarding,
    exitOnboarding,
    clearOnboarding,
    exitButtonText:
      onboardingState?.exitButtonText || defaultState.exitButtonText,
    promotionCode: onboardingState?.promotionCode || defaultState.promotionCode,
    selectedOfferingId: onboardingState?.selectedOfferingId || "",
    label: onboardingState?.label || defaultState.label,
    mode: onboardingState?.mode || defaultState.mode,
  };
};
