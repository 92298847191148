import { Layout } from "./Layout";
import React from "react";

export const ErrorDisplay: React.FC<{ errorCode?: string; title: string }> = ({
  errorCode,
  title,
  children,
}) => (
  <Layout
    title={errorCode ? errorCode : "Error"}
    description="You've reached this page in error."
    className="error_page"
    ensureVisible={true}
  >
    <div className="page_container">
      <div className="error_block">
        <header>
          <h1 className="heading_h1">
            {errorCode ? errorCode : "Server Error"}
          </h1>
          <h2 className="heading_h5">{title}</h2>
        </header>

        <div className="content_block">{children}</div>
        <a className="pill_btn hype" href="/">
          Go back home
        </a>
      </div>
    </div>
  </Layout>
);
