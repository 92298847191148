import { followApiClient } from "./api";

import { useAccount } from "~/lib/client/account";

import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

export const followApiClientContext = createContext(followApiClient);

interface FollowContext {
  loading: boolean;
  followedSlugs: string[];
  follow: (_slug: string) => Promise<void>;
  unfollow: (_slug: string) => Promise<void>;
}

const followContext = createContext<FollowContext>({
  loading: true,
  followedSlugs: [],
  follow: (_slug: string) => Promise.resolve(),
  unfollow: (_slug: string) => Promise.resolve(),
});

export const useProvideFollows = (): FollowContext => {
  const apiClient = useContext(followApiClientContext);
  const [loading, setLoading] = useState(false);
  const [
    {
      permissions: { canAccessAccount },
    },
  ] = useAccount();

  const [followedSlugs, setFollowedSlugs] = useState<string[] | null>(null);

  useEffect(() => {
    if (!canAccessAccount) return;

    setLoading(true);
    apiClient
      .getFollows()
      .then((result) => setFollowedSlugs(result.follows.map((f) => f.slug)))
      .finally(() => setLoading(false));
  }, [apiClient, canAccessAccount]);

  const follow = useCallback(
    async (slug: string) => {
      await apiClient.followTeacher(slug);
      setFollowedSlugs((slugs) => [...new Set([...(slugs || []), slug])]);
    },
    [apiClient]
  );

  const unfollow = useCallback(
    async (slug: string) => {
      await apiClient.unfollowTeacher(slug);
      setFollowedSlugs((slugs) => (slugs || []).filter((s) => s !== slug));
    },
    [apiClient]
  );

  return {
    loading,
    followedSlugs: followedSlugs || [],
    follow,
    unfollow,
  };
};

export const useFollowTeacher = (slug: string) => {
  const { followedSlugs, follow, unfollow } = useContext(followContext);

  const following = followedSlugs.includes(slug);

  return useMemo(
    () => ({
      follow: () => follow(slug),
      unfollow: () => unfollow(slug),
      following,
    }),
    [following, slug, unfollow, follow]
  );
};

export const useFollowingTeacher = () => {
  const { followedSlugs } = useContext(followContext);

  return useMemo(
    () => ({ following: (slug: string) => followedSlugs.includes(slug) }),
    [followedSlugs]
  );
};

export const ProvideFollows = ({ children }: { children: React.ReactNode }) => (
  <followContext.Provider value={useProvideFollows()}>
    {children}
  </followContext.Provider>
);
